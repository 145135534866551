@import '@assets/css/sass/_mixins';

.actions {
  flex-direction: column;
  justify-content: end;
  align-items: unset;

  @include respond-above(sm) {
    flex-direction: row;
    align-items: center;
  }
}
