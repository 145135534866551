/* Gogo Main Style

Table of Contents

00.Variables and Imports
01.Base
02.Buttons
03.Widths-Spacing
04.Borders
05.Badges
06.Breadcrumb
07.Tabs
08.React Siema Carousel
09.Navbar
10.Menu
11.Apps
12.Chat App
13.Survey App
14.Todo App
15.Main
16.Cards
17.Dashboard
18.Calendar
20.Alerts
21.Forms
22.Slider(Range)
23.Navs
24.Tooltip and popover
25.Progress
26.Rating
27.Sortable
28.Spinner
29.Croppper
30.Modal
31.Authorization
32.Html Editors
33.Icons
34.Floating Label
35.Loading
36.Context Menu
37.Videojs
38.Tables
39.Invoice Print
40.Prices
41. Wizard Form
41. Help Center
42. Onboarding Home Card
*/

/* 00.Variables and Imports */
@import url("https://fonts.googleapis.com/css?family=Nunito:300,400,400i,600,700");
@import "~@assets/fonts/iconsmind.css";
@import "~@assets/fonts/simple-line-icons.css";
@import "node_modules/react-modal-video/scss/modal-video.scss";

$main-menu-item-height: 110px;
$main-menu-width: 120px;
$main-menu-width-lg: 110px;
$main-menu-width-md: 100px;
$main-menu-width-xs: 90px;

$sub-menu-width: 280px;
$sub-menu-width-lg: 270px;
$sub-menu-width-md: 250px;
$sub-menu-width-xs: 240px;

$navbar-height: 67px;
$navbar-height-xs: 70px;
$navbar-height-md: 80px;
$navbar-height-lg: 90px;

$main-margin-left: 16px;
// $main-margin-right: 24px;
$main-margin-right: 16px;

$app-menu-width: 280px;

$main-menu-item-height-mobile: 90px;

$menu-collapse-time: 300ms;
$animation-time-long: 1000ms;
$animation-time-short: 200ms;

$border-radius: 0.3rem;
$border-radius-card: 8px;
$border-radius-rounded: 0.75rem;

$theme-color-purple: #922c88;
$theme-color-blue: $theme-color-1;
$theme-color-green: #576a3d;
$theme-color-orange: #e2863b;
$theme-color-red: #880a1f;

$info-color: $theme-color-1;
$success-color: #3e884f;
$warning-color: #b69329;
$error-color: #c43d4b;
$danger-color: #dc3545;

/* 01.Base */
html {
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
  position: fixed;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
  background: $background-color;
}

:root {
  --theme-color-1: #{$theme-color-1};
  --theme-color-2: #{$theme-color-2};
  --theme-color-3: #{$theme-color-3};
  --theme-color-4: #{$theme-color-4};
  --theme-color-5: #{$theme-color-5};
  --theme-color-6: #{$theme-color-6};
  --theme-color-1-10: #{rgba($theme-color-1, 0.1)};
  --theme-color-2-10: #{rgba($theme-color-2, 0.1)};
  --theme-color-3-10: #{rgba($theme-color-3, 0.1)};
  --theme-color-4-10: #{rgba($theme-color-4, 0.1)};
  --theme-color-5-10: #{rgba($theme-color-5, 0.1)};
  --theme-color-6-10: #{rgba($theme-color-6, 0.1)};

  --primary-color: #{$primary-color};
  --foreground-color: #{$foreground-color};
  --separator-color: #{$separator-color};
}

#root {
  height: 100%;
  margin: 0;
  overflow-y: auto;
}

body {
  font-family: "Inter", "Nunito", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: $primary-color;
  background: $background-color;

  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
  position: fixed;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: hidden;
  -webkit-tap-highlight-color: transparent;
}

.fixed-background {
  background: url("~@assets/img/background-blue.jpg") no-repeat center center fixed;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

h1 {
  font-size: 1.75rem;
  padding-bottom: 10px;
  display: inline-block;

  @include respond-below(sm) {
    font-size: 1.3rem;
    padding-top: 0.5rem;
  }
}

h2 {
  font-size: 1.4rem;

  @include respond-below(sm) {
    font-size: 1.1rem;
  }
}

h3 {
  font-size: 1.3rem;

  @include respond-below(sm) {
    font-size: 1rem;
  }
}

h3 {
  font-size: 1.2rem;

  @include respond-below(sm) {
    font-size: 1rem;
  }
}

h4 {
  font-size: 1.15rem;

  @include respond-below(sm) {
    font-size: 0.9rem;
  }
}

h5 {
  font-size: 1.1rem;

  @include respond-below(sm) {
    font-size: 0.9rem;
  }
}

h6 {
  font-size: 1rem;

  @include respond-below(sm) {
    font-size: 0.85rem;
  }
}

hr {
  border-top: 1px solid $separator-color;
}

textarea {
  resize: none;
}

.disable-text-selection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

::selection {
  background: lighten($theme-color-3, 20%);
}

::-moz-selection {
  background: lighten($theme-color-3, 20%);
}

.map-item {
  height: 400px;
  width: 100%;
}

.scrollbar-container {
  margin-right: -15px;
  padding-right: 15px;
  position: relative;
}

.ps__rail-y {
  width: 5px;
}

.ps__thumb-y {
  left: 0;
}

.ps__rail-y:hover>.ps__thumb-y,
.ps__rail-y:focus>.ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  width: 5px;
  left: 0;
}

.ps__thumb-y {
  background-color: $separator-color;
}

.video-play-icon {
  width: 100%;
  height: 100%;
  position: absolute;

  span {
    position: absolute;
    background: rgba(255, 255, 255, 0.7);
    height: 1.25em;
    border-radius: 0.75em;
    line-height: 0.65em;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.75em;
    width: 2em;
    text-align: center;

    &:before {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 0 8px 12px;
      font-size: 0;
      border-color: transparent transparent transparent $theme-color-1;
    }
  }

  &:hover,
  &:active {
    span {
      background: rgba(255, 255, 255, 0.85);
    }
  }
}

.logo-single {
  width: 200px;
  height: 50px;
  background: url($logoPath) no-repeat;
  background-position: center center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  background-size: contain;
}

.list-item-heading {
  font-size: 1rem;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.no-transition {
  transition: none !important;
}

.display-1 {
  @include respond-below(sm) {
    font-size: 2.8rem;
  }
}

.display-2 {
  @include respond-below(sm) {
    font-size: 2.6rem;
  }
}

.display-3 {
  @include respond-below(sm) {
    font-size: 2.4rem;
  }
}

.display-4 {
  @include respond-below(sm) {
    font-size: 2.2rem;
  }
}

.display-5 {
  font-size:2.2rem;
  font-weight:300;
  line-height:1.2;
  @include respond-below(sm) {
    font-size: 1.5rem;
  }
}

.lead {
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 2rem;

  @include respond-below(sm) {
    font-size: 1.1rem;
    line-height: 1.6rem;
  }
}

a {
  color: $primary-color;
  transition: color $animation-time-short;

  &:hover,
  &:active {
    text-decoration: initial;
    color: $theme-color-1;
  }
}

p {
  font-size: 0.9rem;
  line-height: 1.3rem;
  font-family: "Nunito", sans-serif;
}

.text-large {
  font-size: 1.9rem !important;
}

.text-one {
  font-size: .85rem !important;
}

.text-xlarge {
  font-size: 2.7rem !important;
}

.text-small {
  font-size: 0.76rem;
  line-height: 0.9rem;
}

.text-white {
  color: $button-text-color !important;
}

.text-extra-small {
  font-size: 0.6rem;
}

.text-default {
  color: $primary-color !important;
}

.text-muted {
  color: $muted-color !important;
}

.text-semi-muted {
  color: $secondary-color !important;
}

.font-weight-medium {
  font-weight: 500;
}

.font-weight-semibold {
  font-weight: 600;
}

.color-theme-1 {
  color: $theme-color-1;
}

.color-theme-2 {
  color: $theme-color-2;
}

.view-icon {
  font-size: 20px;
  color: $secondary-color;

  &:hover {
    color: $theme-color-1;
  }

  &.s {
    font-size: 18px;
  }
}

#displayOptions {
  a {
    cursor: pointer;
  }

  a.active i {
    color: $theme-color-1;
  }

  button {
    border-color: $secondary-color;
    color: $secondary-color;

    &:hover {
      background-color: $theme-color-1;
      border-color: $theme-color-1;
      color: $button-text-color;
    }
  }

  .btn-outline-dark:not(:disabled):not(.disabled):active,
  .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show>.btn-outline-dark.dropdown-toggle {
    background-color: $theme-color-1;
    border-color: $theme-color-1;
    color: $button-text-color;
  }

  .view-icon {
    svg {
      width: 19px;
    }

    .view-icon-svg {
      fill: $secondary-color;
    }

    &:hover,
    &.active {
      .view-icon-svg {
        fill: $theme-color-1;
      }
    }
  }
}

.text-theme-2 {
  color: $theme-color-2 !important;
}

.text-theme-3 {
  color: $theme-color-3 !important;
}

.text-primary,
.text-theme-1 {
  color: $theme-color-1 !important;
}

.text-secondary {
  color: $primary-color !important;
}

.main-heading {
  border-bottom: 1px solid $separator-color;
}

.separator {
  border-bottom: 1px solid $separator-color;
}

.alert-dismissible .close {
  padding: 0.5rem 1.25rem;
}

.rounded-alert {
  border-radius: 4px !important;
}

/* 43.Rounded */

.rounded {
  .notification {
    border-radius: $border-radius-rounded;
  }
}

.img-thumbnail {
  border-radius: $border-radius;
  padding: 0;
  border: initial;
}

.list-thumbnail {
  border-radius: $border-radius;
  padding: 0;
  border: initial;
  height: auto;
  max-width: unset;
  height: 85px;
  object-fit: cover;
  width: unset !important;

  @include respond-below(md) {
    height: 80px;
  }

  @include respond-below(xs) {
    height: 70px;
  }

  &.responsive {
    @include respond-below(md) {
      width: unset;
      height: 136px;
    }

    @include respond-below(xs) {
      width: 110px !important;
      height: 100%;
    }

    @include respond-below(xxs) {
      width: 90px !important;
      height: 100%;
    }
  }

  &.small {
    height: 60px;
    font-size: 1rem;

    @include respond-below(md) {
      height: 55px;
    }

    @include respond-below(xs) {
      height: 50px;
    }
  }

  &.xsmall {
    height: 40px;
    font-size: 1rem;

    @include respond-below(md) {
      height: 40px;
    }

    @include respond-below(xs) {
      height: 40px;
    }
  }
}

.list-thumbnail-letters {
  width: 85px;
  height: 85px;
  background: $theme-color-1;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 1.25rem;
  color: $button-text-color;

  @include respond-below(md) {
    width: 80px;
    height: 80px;
  }

  @include respond-below(xs) {
    width: 70px;
    height: 70px;
  }

  &.small {
    width: 60px;
    height: 60px;
    font-size: 1rem;

    @include respond-below(md) {
      width: 55px;
      height: 55px;
    }

    @include respond-below(xs) {
      width: 50px;
      height: 50px;
    }
  }
}

.white {
  color: $button-text-color !important;
}

.blue {
  color: $muted-color !important;
}

.min-height-5 {
  min-height: 5rem;
}

/* 02.Layout */
@import './core/layout.scss';

/* 02.Buttons */
@import './core/buttons.scss';

/* 03.Widths-Spacing */
@import './core/widths-spacing.scss';

.float-none-xs {
  @include respond-below(xs) {
    float: initial !important;
  }
}

/* 04.Borders */
@import './core/borders.scss';

/* 05.Badges */
@import './core/badges.scss';

/* 06.Breadcrumb */
@import './core/breadcrumb.scss';

/* 07.Tabs */
@import './core/tabs.scss';

// 08.React Siema Carousel
.react-siema-container {
  overflow: hidden;
  padding-bottom: 15px;
  padding-top: 5px;
}

.slider-nav {

  .left-arrow,
  .right-arrow {
    font-size: 20px;
    color: $theme-color-1;
    display: inline-block;
    vertical-align: middle;
    margin: 0 15px;
    padding-top: 14px;
  }

  .slider-dot-container {
    display: inline-block;
  }

  .btn {

    &:hover,
    &:focus,
    &:active {
      text-decoration: initial;
    }
  }
}

.slider-dot {
  width: 6px;
  height: 6px;
  border-radius: 10px;
  background: $separator-color;
  outline: initial !important;
  border: initial;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;

  &.active {
    background: $theme-color-1;
  }
}

.react-siema-container .card .card-body {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.react-siema-container .card .w-50 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/* 09.Navbar*/
@import './core/navbar.scss';

/* 09.Sidebar*/
@import './core/sidebar.scss';

/* 13.Survey App*/
@import './views/survey-app.scss';

/* 14.Todo App*/
@import './views/todo-app.scss';

/* 15.Main*/
@import './core/main.scss';

/* 16.Cards*/
@import './core/cards.scss';

/* 17.Dashboard*/
@import './views/dashboards.scss';

/* 18.Calendar */
.big-calendar-header {
  margin-bottom: 1em;
}

.rbc-month-header {
  min-height: 50px;
}

.rbc-today {
  background: initial;
}

.calendar-prev-btn,
.calendar-next-btn {
  outline: initial !important;
  box-shadow: initial !important;
  border-radius: 40px !important;
  text-align: center;
  min-width: 30px;
  height: 30px;
  padding: 0.55rem 0;
  background: $theme-color-1;
  color: $foreground-color;
  border: 1px solid $theme-color-1;
  line-height: 0.9 !important;
  font-size: 0.76rem;
  font-weight: normal !important;

  span {
    line-height: 1 !important;
    font-size: 0.76rem;
    font-weight: normal !important;
  }

  &:hover {
    background-color: transparent;
    border-color: lighten($theme-color-1, 10%);
    color: $theme-color-1;
  }
}

.calendar-prev-btn {
  margin-right: 5px;
}

.calendar-today-btn {
  padding: 0.4em 1.3em !important;
  height: unset !important;
}

.rbc-month-row {
  min-height: 5em;
  z-index: 1;

  @include respond-below(xs) {
    min-height: 3em;
  }
}

.rbc-month-view {
  border: initial;
}

.rbc-off-range-bg {
  background: initial;
}

.rbc-off-range {
  color: $primary-color;
  opacity: 0.3;
}

.rbc-day-bg+.rbc-day-bg,
.rbc-month-row+.rbc-month-row,
.rbc-header+.rbc-header,
.rbc-header {
  border-color: $separator-color-light !important;
}

.rbc-header {
  padding: 15px 5px;
  color: $theme-color-1;
}

.rbc-date-cell {
  padding: 10px;

  a {
    border-radius: 50%;
    width: 28px;
    height: 28px;
    text-align: center;
    vertical-align: middle;
    padding: 5px;
    font-weight: initial;
    display: inline-block;
  }
}

.rbc-date-cell.rbc-now a {
  background: $theme-color-1;
  color: $foreground-color;
}

.rbc-event {
  font-size: 0.85em;
  border-radius: 25px;
  text-align: center;
  padding: 0px 5px;
  background: $theme-color-2;
}

.fc-basic-view .fc-body .fc-row {
  min-height: 6em;

  @include respond-below(xs) {
    min-height: 3em;
  }
}

.fc-bootstrap4 .fc-day-top .fc-day-number {
  border-radius: 50%;
  width: 18px;
  height: 18px;
  text-align: center;
  vertical-align: middle;
  padding: 5px;
}

.fc-bootstrap4 td.fc-today {
  background: initial;

  .fc-day-number {
    background: $theme-color-1;
    color: $button-text-color;
  }
}

.fc-day-grid-container {
  height: 100%;
  overflow: visible;
}

.fc-row .fc-content-skeleton td,
.fc-row .fc-helper-skeleton td {
  padding: 5px;
}

.fc-view,
.fc-view>table {
  border-top: initial;
}

.fc table {
  border: initial;
}

.fc .table-bordered thead th,
.fc .table-bordered thead td {
  border-top: initial;
  border-bottom: initial;
  border-left: initial;
}

.fc td:first-of-type,
.fc th:first-of-type {
  border-left: initial;
  border-bottom: initial;
}

.fc td:last-of-type,
.fc th:last-of-type {
  border-right: initial;
  border-bottom: initial;
}

.fc-body .fc-row:last-of-type td {
  border-bottom: initial;
}

.fc tbody>tr>td.fc-widget-content:first-of-type {
  border-left: initial;
  border-right: initial;
  border-bottom: initial;
}

.fc table,
.fc .table-bordered th,
.fc .table-bordered td {
  border-color: $separator-color-light !important;
}

.fc-day-header {
  color: $theme-color-1;

  span {
    padding: 10px;
    display: inline-block;
  }
}

.fc-event {
  border: initial;
}

.fc-event,
.fc-event-dot {
  background-color: $theme-color-2;
  color: $button-text-color !important;
  padding: 1px 6px;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
}

/* 20.Alerts */
@import './core/alerts.scss';

/* 20.Notification */
@import './plugins/notification.scss';

/* 21.Forms */
@import './core/forms.scss';

.jumbotron {
  background: initial;
  padding: 2rem 1rem;
}

/* 22.Slider(Range) */
@import './plugins/react-range-slider.scss';

/* 23.Navs */
@import './core/navs.scss';

/* 25.Progress */
@import './plugins/progress.scss';

/* 26.Rating */
@import './plugins/rating.scss';

/* 27.Sortable */
@import './plugins/sortable.scss';

/* 28.Spinner */
@import './plugins/spinner.scss';

/* 29.Croppper */
@import './plugins/cropper.scss';

/* 30.Modal */
@import './core/modal.scss';

/* 31.Authorization */
.auth-card {
  display: flex;
  flex-direction: row;
  max-width: 500px;
  margin: auto;
  @include depth(1);

  .image-side {
    width: 100%;
    background: url('~@assets/img/post-register.svg') no-repeat center top;
    background-size: contain;
    border-top-right-radius: $border-radius-card;
    border-bottom-right-radius: $border-radius-card;
    .h3 {
      line-height: 0.8rem;
    }
  }

  .form-side {
    width: 100%;
    padding: 60px;
  }

  .onboarding {
    width: 60%;
    padding: 60px;
    gap: 14px;
  }

  .register-form {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @include respond-below(md) {
    flex-direction: column;

    .image-side {
      width: 100%;
      padding: 60px;
      border-bottom-right-radius: initial;
      border-bottom-left-radius: initial;
      border-top-left-radius: $border-radius-card;
      border-top-right-radius: $border-radius-card;
    }

    .form-side {
      width: 100%;
      padding: 60px;
    }
  }

  @include respond-below(sm) {
    p.h2 {
      font-size: 1.6rem;
    }
  }

  @include respond-below(xs) {
    flex-direction: column;

    .image-side {
      padding: 35px 30px;
    }

    .form-side {
      padding: 35px 30px;
    }

    .logo-single {
      width: 150px;
    }

    p.h2 {
      font-size: 1.4rem;
    }
  }
}

/* 32.Html Editors */
@import './plugins/html-editor.scss';

/* 33.Icons */
.simple-line-icons .glyph,
.mind-icons .glyph {
  width: 14.28%;
  text-align: center;
  float: left;
  height: 100px;

  .glyph-icon,
  .fa {
    font-size: 32px;
  }

  .author-name {
    display: none;
  }

  .class-name {
    font-size: 0.76rem;
    color: $muted-color !important;
  }

  @include respond-below(lg) {
    width: 16.66%;
  }

  @include respond-below(md) {
    width: 20%;
  }

  @include respond-below(sm) {
    width: 25%;
  }

  @include respond-below(xs) {
    width: 50%;
  }
}

.chart-container {
  height: 300px;
}

.theme-colors {
  width: 280px;
  position: fixed;
  z-index: 1030;
  top: 50%;
  right: 0;
  background: $foreground-color;
  @include depth(1);
  transform: translate(280px, -50%);
  transition: transform 0.4s ease-out;
  padding-top: 10px;
  padding-bottom: 10px;

  .theme-button {
    position: absolute;
    left: -45px;
    background: $foreground-color;
    padding-right: 7px;
    padding-top: 20px;
    padding-left: 0px;
    padding-bottom: 13px;
    border-radius: 0.2rem;
    color: $primary-color;
    box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.04);
    font-size: 20px;
    top: 50%;
    transform: translateY(-50%);
    color: $theme-color-1;
  }

  .theme-color {
    width: 24px;
    height: 24px;
    display: inline-block;
    border-radius: 20px;
    transition: background 0.25s;

    &.active,
    &:hover {
      background: $foreground-color;
    }
  }

  .theme-color-purple {
    border: 3px solid $theme-color-purple;
    background: $theme-color-purple;
  }

  .theme-color-blue {
    border: 3px solid $theme-color-blue;
    background: $theme-color-blue;
  }

  .theme-color-orange {
    border: 3px solid $theme-color-orange;
    background: $theme-color-orange;
  }

  .theme-color-green {
    border: 3px solid $theme-color-green;
    background: $theme-color-green;
  }

  .theme-color-red {
    border: 3px solid $theme-color-red;
    background: $theme-color-red;
  }

  &.shown {
    transform: translate(0, -50%);
  }
}

/* 34.Floating Label */

.has-float-label {
  display: block;
  position: relative;
}

.has-float-label label::after,
.has-float-label>span::after {
  background: $foreground-color !important;
}

.has-top-label label,
.has-top-label>span,
.has-float-label label,
.has-float-label>span {
  color: rgba($primary-color, 0.7);
}

.has-float-label .text-danger label,
.has-float-label .text-danger>span {
  color: #dc3545;
}

.dirty-border {
  border-style: solid;
  border-width: 1px;
  border-color: #dc3545;
}

.has-float-label label:not(.form-check-label),
.has-float-label>span:last-of-type {
  position: absolute;
  cursor: text;
  font-size: 90%;
  opacity: 1;
  top: -0.4em;
  left: 1.75em;
  z-index: 3;
  line-height: 1;
  padding: 0px 5px;
  background: inherit;
}

// TODO cambiar left a algo mejor codeado en la clase anterior
.login-left .has-float-label>span:last-of-type {
  left: 1rem;
}

.has-float-label label:not(.form-check-label)::after,
.has-float-label>span::after {
  content: " ";
  display: block;
  position: absolute;
  height: 5px;
  top: 3px;
  left: -0.2em;
  right: -0.2em;
  z-index: -1;
}

.has-float-label .form-control::-webkit-input-placeholder {
  opacity: 1;
}

.has-float-label .form-control::-moz-placeholder {
  opacity: 1;
}

.has-float-label .form-control:-ms-input-placeholder {
  opacity: 1;
}

.has-float-label .form-control::placeholder {
  opacity: 1;
}

.has-float-label .form-control:placeholder-shown:not(:focus)::-webkit-input-placeholder {
  opacity: 0;
}

.has-float-label .form-control:placeholder-shown:not(:focus)::-moz-placeholder {
  opacity: 0;
}

.has-float-label .form-control:placeholder-shown:not(:focus):-ms-input-placeholder {
  opacity: 0;
}

.has-float-label .form-control:placeholder-shown:not(:focus)::placeholder {
  opacity: 0;
}

.input-group .has-float-label {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin-bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.input-group .has-float-label .form-control {
  width: 100%;
}

.input-group .has-float-label:not(:last-child),
.input-group .has-float-label:not(:last-child) .form-control {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: 0;
}

.input-group .has-float-label:not(:first-child),
.input-group .has-float-label:not(:first-child) .form-control {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.form-group.has-top-label .form-control,
.form-group.has-top-label .react-tagsinput,
.form-group.has-top-label .react-datepicker__input-container input,
.form-group.has-top-label .react-select__value-container {
  padding: 1.7rem 0.75rem 0.5rem 0.75rem !important;
}

.has-top-label {
  display: block;
  position: relative;

  .react-tagsinput-input {
    padding: 0;
    margin: 0;
    font-size: 0.8rem;
    line-height: 1;
  }
}

.has-top-label .react-select__value-container .css-rsyb7x {
  margin: 0 !important;
  padding: 0 !important;
}

.has-top-label label,
.has-top-label>span:last-of-type {
  position: absolute;
  cursor: text;
  font-size: 76%;
  opacity: 1;
  top: 0.7rem;
  left: 0.75rem;
  z-index: 3;
  line-height: 1;
  padding: 0 1px;
  font-weight: 600;
}

.has-top-label label::after,
.has-top-label>span::after {
  content: " ";
  display: block;
  position: absolute;
  height: 2px;
  top: 50%;
  left: -0.2em;
  right: -0.2em;
  z-index: -1;
}

.has-top-label .form-control::-webkit-input-placeholder {
  opacity: 1;
}

.has-top-label .form-control::-moz-placeholder {
  opacity: 1;
}

.has-top-label .form-control:-ms-input-placeholder {
  opacity: 1;
}

.has-top-label .form-control::placeholder {
  opacity: 1;
}

.has-top-label .form-control:placeholder-shown:not(:focus)::-webkit-input-placeholder {
  opacity: 0;
}

.has-top-label .form-control:placeholder-shown:not(:focus)::-moz-placeholder {
  opacity: 0;
}

.has-top-label .form-control:placeholder-shown:not(:focus):-ms-input-placeholder {
  opacity: 0;
}

.has-top-label .form-control:placeholder-shown:not(:focus)::placeholder {
  opacity: 0;
}

.has-top-label .form-control:placeholder-shown:not(:focus)+* {
  font-size: 150%;
  opacity: 0.5;
  top: 0.3em;
}

.has-top-label .react-select__single-value {
  top: unset !important;
  bottom: -3px !important;
  margin-left: 0 !important;
}

.input-group .has-top-label {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin-bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.input-group .has-top-label .form-control {
  width: 100%;
}

.input-group .has-top-label:not(:last-child),
.input-group .has-top-label:not(:last-child) .form-control {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: 0;
}

.input-group .has-top-label:not(:first-child),
.input-group .has-top-label:not(:first-child) .form-control {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.form-group.has-top-label .form-control,
.form-group.has-top-label .react-tagsinput,
.form-group.has-top-label .react-datepicker__input-container input,
.form-group.has-top-label .react-select__value-container {
  min-height: calc(3.3rem + 2px) !important;
}

.form-group.has-top-label select.form-control:not([size]):not([multiple]) {
  height: calc(3.3rem + 2px);
  padding: 1.7rem 0.75rem 0.5rem 0.5rem;
}

/* 35.Loading */
@import './core/loading.scss';

/* 36.Context Menu */
.react-contextmenu {
  box-shadow: initial;
  border-radius: $border-radius;
  background: $input-background;
  border: 1px solid rgba($primary-color, 0.15);
  padding: 0.5rem 0;
  opacity: 0;
}

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
  z-index: 9999;
}

.react-contextmenu-item {
  padding: 0.5rem 1.5rem;
  background: $input-background;
  color: $primary-color;
  cursor: pointer;

  span {
    font-family: "Nunito", sans-serif;
    font-size: 0.8rem;
    font-weight: 400;
    margin-left: 0.5rem;
    line-height: 15px;
  }

  &:hover {
    color: $primary-color;
    text-decoration: none;
    background-color: $background-color;
  }
}

.react-contextmenu-item:not(.react-contextmenu-item--disabled):hover {
  color: $primary-color;
  text-decoration: none;
  background-color: $background-color;
}

.card.react-contextmenu--visible,
.card.active {
  @include depth(2);
}

/* 37.Videojs */
.video-js .vjs-big-play-button {
  background: $input-background;
  height: 1.2em;
  border-radius: 0.75em;
  line-height: initial;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 3.5em;
  width: 2.5em;
  border: 0.06666em solid $input-background;

  .vjs-icon-placeholder {
    color: $theme-color-1;
  }
}

.video-js:hover .vjs-big-play-button,
.video-js .vjs-big-play-button:focus {
  background-color: darken($input-background, 5%);
  border-color: darken($input-background, 5%);
}

.vjs-control {
  text-shadow: initial !important;
  outline: initial !important;
}

.video-js .vjs-control-bar {
  background: initial;
  margin: 1.75rem;
  width: calc(100% - 3.5rem);

  .vjs-control.vjs-button,
  .vjs-remaining-time,
  .vjs-volume-panel {
    margin-right: 0.5em;
    background: $input-background;
    color: $theme-color-1;
    border-radius: 15px;
    @include depth(2);
  }

  .vjs-progress-control.vjs-control {
    background: $input-background;
    border-radius: 15px;
    margin-right: 0.5em;
    @include depth(2);
  }

  .vjs-mute-control.vjs-control {
    box-shadow: initial;
  }

  .vjs-progress-holder {
    height: 3px;
    font-size: 1.6em !important;
  }

  .vjs-load-progress,
  .vjs-load-progress div {
    background-color: rgba($theme-color-1, 0.2);
  }

  .vjs-play-progress:before {
    font-size: 0.55em;
    top: -0.2em;
  }

  .vjs-progress-holder {
    margin: 0 17px;
  }

  .vjs-slider {
    text-shadow: initial !important;
    outline: initial !important;
    background-color: darken($separator-color-light, 10%);
  }

  .vjs-play-progress {
    background: $theme-color-1;
  }

  .vjs-play-progress:before {
    color: $theme-color-1;
  }

  .vjs-volume-horizontal {
    margin-left: -1.5em;
    width: 4em;
  }

  .vjs-volume-panel .vjs-volume-level {
    background: $theme-color-1;
  }
}

.video-js.audio {
  background: initial;

  .vjs-big-play-button {
    display: none;
  }

  .vjs-control-bar {
    display: flex;
  }

  .vjs-fullscreen-control {
    display: none;
  }

  .vjs-control-bar {
    margin-bottom: 0;
  }

  .vjs-control.vjs-button,
  .vjs-remaining-time,
  .vjs-volume-panel {
    box-shadow: 0 0px 2px rgba(0, 0, 0, 0.15), 0 0px 1px rgba(0, 0, 0, 0.2);
  }

  .vjs-progress-control.vjs-control {
    box-shadow: 0 0px 2px rgba(0, 0, 0, 0.15), 0 0px 1px rgba(0, 0, 0, 0.2);
  }

  .vjs-mute-control {
    box-shadow: initial !important;
  }

  .vjs-loading-spinner {
    display: none !important;
  }
}

.image-button {
  width: 80%;
  height: 100%;
}
.video-modal {
  box-shadow: none;
}

.video-modal-iframe-wrap {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
}
.video-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@import './core/table.scss';

/* 39.Invoice*/

.invoice-react {
  .invoice-contents {
    background-color: $foreground-color;
    height: 1200px;
    max-width: 830px;
    position: relative;
  }
}

@media print {
  body {
    background: $foreground-color;
    height: 100%;
  }

  main {
    margin: 0 !important;
  }

  .navbar,
  .sidebar,
  .theme-colors {
    display: none;
  }

  main .container-fluid .row:not(.invoice) {
    display: none;
  }

  .invoice {
    width: 100%;
    max-width: 100%;

    &.row {
      margin: 0;
    }

    [class*="col"] {
      padding: 0;
    }

    .invoice-contents {
      width: 100%;
      max-width: 100% !important;
      height: 1370px !important;
    }
  }
}

/* Prices */
.equal-height-container {
  .col-item {
    .card {
      height: 100%;
    }

    .card-body {
      @include respond-below(md) {
        text-align: left;
      }

      @include respond-below(xs) {
        text-align: center;
      }
    }

    .price-top-part {
      text-align: center;

      * {
        text-align: center !important;
      }

      @include respond-below(md) {
        padding-left: 0;
        padding-right: 0;
        width: 40%;
      }

      @include respond-below(xs) {
        width: initial;
      }
    }

    .price-feature-list {
      justify-content: space-between;

      ul {
        margin: 0 auto;
        align-self: flex-start;
        margin-bottom: 1rem;

        li p {
          text-align: center !important;
        }
      }
    }
  }
}

.table-heading {
  box-shadow: initial;
  background: initial;
}

i.large-icon {
  font-size: 38px;
  line-height: 90px;
  color: $theme-color-1;

  @include respond-below(sm) {
    line-height: 70px;
  }
}

.large-icon.initial-height {
  line-height: initial;

  @include respond-below(sm) {
    line-height: initial;
  }
}

/* 40. Date Picker*/
.btn-datepicker {
  right: 0;
  bottom: 0;
  height: 3em;
}

.datepicker {
  z-index: 2;

  &__years {
    .react-datepicker__month {
      display: none;
    }
  }

  &__years,
  &__months,
  &__quarters {
    width: 100%;
    display: flex;
    justify-content: center;
    border: none;
  }

  &__quarters,
  &__months {
    .react-datepicker {

      &__navigation,
      &__header {
        display: none;
      }
    }
  }

  .react-datepicker {
    border: none;

    &__quarter-wrapper {
      display: flex;
    }
  }

  .react-datepicker__month-container {
    width: 100%;
  }

  .react-datepicker__month-wrapper {
    display: flex;
    justify-content: space-around;
  }
}

.top-right-button-container {
  float: right;
  position: relative;

  @include respond-below(xs) {
    float: right;
    position: relative;
  }

  .top-right-button {
    @include respond-below(xs) {
      display: flex;
      flex-grow: 1;
      justify-content: center;
      align-items: center;
      margin-right: 5px;
    }
  }

  .dropdown {
    @include respond-below(xs) {
      display: flex;
      flex-grow: 1;
      margin-right: 5px;
    }
  }
}

.login-link {
  padding: 0 !important;
  margin: 0 !important;

  @include respond-below(md) {
    padding: 5px !important;
  }
}

.pricing__buttons {
  width: 100%;
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  flex-flow: wrap;
  justify-content: space-between;

  button {
    width: 46%;
    margin: 0.5rem;
    padding-left: 0;
    padding-right: 0;
    border: none;
    margin-top: 0 !important;
    color: black;
    background: white;
    border: 0.4px solid rgba(64, 64, 64, 0.25);
    border-bottom-left-radius: 0.5em;
    border-top-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    border-top-right-radius: 0.5em;

    span {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    &.is_active,
    &:hover {
      color: white;
      background: $info-color;
    }

    -webkit-box-shadow: 5px 5px 15px 5px rgba(64, 64, 64, 1);
    -moz-box-shadow: 5px 5px 15px 5px rgba(64, 64, 64, 1);
    box-shadow: 5px 5px 15px 5px rgba(64, 64, 64, 1);
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .pricing__buttons {
    margin-bottom: 2em;
    flex-wrap: nowrap;
    -webkit-box-shadow: 5px 5px 15px 0px rgba(176, 176, 176, 1);
    -moz-box-shadow: 5px 5px 15px 0px rgba(176, 176, 176, 1);
    box-shadow: 5px 5px 15px 0px rgba(176, 176, 176, 1);

    border-bottom-left-radius: 0.5em;
    border-top-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    border-top-right-radius: 0.5em;

    button:first-of-type {
      border-bottom-left-radius: 0.5em;
      border-top-left-radius: 0.5em;
    }

    button:not(:first-of-type) {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }

    button:last-of-type {
      border-bottom-right-radius: 0.5em;
      border-top-right-radius: 0.5em;

      span {
        border-right: 0;
      }
    }

    button:not(:last-of-type) {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    button {
      border: none;
      margin: 0;

      span {
        border-right: 0.1px solid rgba(176, 176, 176, 1);
      }
    }
  }
}

/*
---------------------------------------------
pricing
---------------------------------------------
*/

.pending_payment {
  background: #ffffff;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 30px;
  & .pricing-header {
    margin-top: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    min-height: 80px;
    .pricing-content-active {
      display: none;
    }
  }
  &.pricing-header .pricing-title {
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 1px;
    color: #3b566e;
    width: 100%;
  }
  &.pricing-content {
    margin-top: 40px;
  }

  & .pricing-body {
    text-align: center;
  }

  & .pricing-body h3 {
    font-weight: 600;
  }

  & .price-counter {
    min-height: 51px;
    margin: 20px;
  }

  & .price-counter .input-group {
    margin: auto;
    margin-top: 10px;
    max-width: 35%;
  }

  & .price-counter .btn {
    padding: 7px;
  }

  & .price-counter input::-webkit-outer-spin-button,
  & .price-counter input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
  }

  & .price-counter input[type="number"] {
    -moz-appearance: textfield !important;
  }

  & .price-counter input {
    padding: 0px;
    text-align: center;
  }

  & .pricing-body .list li.active {
    color: #3b566e;
  }

  & .pricing-body .list li.captive {
    color: #2061bb;
    font-weight: bold;
  }

  & .pricing-footer {
    text-align: center;
    margin-bottom: 40px;
  }

  & .pricing-footer a {
    margin: auto;
    display: block;
    width: 160px;
    height: 36px;
    margin-bottom: 40px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    text-align: center;
    line-height: 36px;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.75px;
    color: #2061bb;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
  }

  &:hover {
    border: 1px solid $theme-color-blue;
    opacity: 1;
  }
  & .large-icon {
    width: 70px;
    color: #2061bb;
    margin-bottom: 4px;
  }

}
.pricing-item {
  background: #ffffff;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 30px;
  padding-top: 30px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.order-card {
  opacity: 1 !important;
}
.pricing-item .pricing-header {
  margin-top: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  min-height: 80px;
}

.pricing-item .pricing-header .pricing-title {
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 1px;
  color: #3b566e;
  width: 100%;
}

.price-extras {
  min-height: 21px;
  margin-bottom: 40px;
}

.pricing-item .pricing-content {
  margin-top: 40px;
}
.pricing-content {
  margin: 20px;
}

.pricing-item .pricing-body {
  text-align: center;
}

.pricing-item .pricing-body h3 {
  font-weight: 600;
}
.pricing-item .pricing-body__airbnb-title h3 {
  color: #E61E4D !important;
}

.pricing-item .price-counter {
  min-height: 51px;
  margin: 20px;
}

.pricing-item .price-counter .input-group {
  margin: auto;
  margin-top: 10px;
  max-width: 49%;
}

.pricing-item .price-counter .btn {
  padding: 7px;
}

.pricing-item .price-counter input::-webkit-outer-spin-button,
.pricing-item .price-counter input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.pricing-item .price-counter input[type="number"] {
  -moz-appearance: textfield !important;
}

.pricing-item .price-counter input {
  padding: 0px;
  text-align: center;
}
.price-wrapper__airbnb .currency {
    color: #E61E4D !important;
}
.price-wrapper__airbnb .price {
  color: #E61E4D !important;
}
.price-wrapper__airbnb .period {
  color: #E61E4D !important;
}
.price-wrapper {
  text-align: center;
  min-height: 2.5em;
}

.price-wrapper .currency {
  height: 47px;
  font-weight: 600;
  font-size: 20px;
  color: #2061bb;
  position: relative;
  top: -15px;
}

.price-wrapper .price {
  font-weight: 700;
  font-size: 34px;
  color: #2061bb;
  letter-spacing: 2.12px;
}

.price-wrapper .period {
  font-weight: 700;
  font-size: 14px;
  color: #2061bb;
  letter-spacing: 0.88px;
}

.pricing-item .pricing-body .list li {
  text-align: center;
  margin-bottom: 12px;
  font-weight: 400;
  font-size: 14px;
  color: #bcd2e6;
  letter-spacing: 0.88px;
}

.pricing-footer-button__airbnb {
  background-color: #E61E4D !important;
  border: 1px solid #E61E4D;
}

.pricing-item .pricing-body .list li.active {
  color: #3b566e;
}

.pricing-item .pricing-body .list li.captive {
  color: #2061bb;
  font-weight: bold;
}

.pricing-item .pricing-footer {
  text-align: center;
  margin-bottom: 40px;
}

.pricing-item .pricing-footer a {
  margin: auto;
  display: block;
  width: 160px;
  height: 36px;
  border: 1px solid #2061bb;
  margin-bottom: 40px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  text-align: center;
  line-height: 36px;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.75px;
  color: #2061bb;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.pricing-item .pricing-footer a:hover {
  background: #2061bb;
  color: #fff;
}

.pricing-buttons {
  width: 100%;
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  flex-flow: wrap;
  justify-content: space-between;
  margin-bottom: 15px;
}

.pricing-buttons button {
  width: 50%;
  margin: 0.5rem 0;
  padding: 0.375rem 0rem;
  border: none;
  margin-top: 0 !important;
  color: black;
  background-color: white;
  outline: none;

  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  border: 0.4px solid rgba(64, 64, 64, 0.25);

  border-bottom-left-radius: 0.5em;
  border-top-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  border-top-right-radius: 0.5em;

  -webkit-box-shadow: 1px 1px 10px 0px rgb(175, 175, 175);
  -moz-box-shadow: 1px 1px 10px 0px rgb(175, 175, 175);
  box-shadow: 1px 1px 10px 0px rgb(175, 175, 175);
}

.pricing-buttons button span {
  display: flex;
  justify-content: center;
  width: 100%;
}

.pricing-buttons button.active,
.pricing-buttons button:hover {
  color: white;
  background: #2061bb;
  box-shadow: none;
}
.pricing-item-airbnb {
  padding-top: 0px !important;
}

.pricing-item {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  flex-direction: column;
  opacity: 0.7;

}
.pricing-item:hover {
  border: 1px solid $theme-color-blue;
  opacity: 1;
}

.pricing-item-airbnb:hover {
  border: 1px solid #E61E4D;

}

.pricing-item.active {
  display: flex;
}

.pricing-item .large-icon {
  width: 70px;
  color: #2061bb;
  margin-bottom: 4px;
}

@media only screen and (min-width: 768px) and (max-width: 992px) {


  .pricing-content {
    margin-right: 20px;
  }


  .pricing-item {
    height: auto;

  }


  .pricing-item .pricing-header {
    margin-top: 0px;
  }
}

@media (min-width: 992px) {
  .pricing-buttons {
    margin-bottom: 2em;
    flex-wrap: nowrap;
    -webkit-box-shadow: 1px 1px 10px 0px rgb(175, 175, 175);
    -moz-box-shadow: 1px 1px 10px 0px rgb(175, 175, 175);
    box-shadow: 1px 1px 10px 0px rgb(175, 175, 175);

    border-bottom-left-radius: 0.5em;
    border-top-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    border-top-right-radius: 0.5em;

    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
  }

  .pricing-buttons button:first-of-type {
    border-bottom-left-radius: 0.5em;
    border-top-left-radius: 0.5em;
  }

  .pricing-buttons button:not(:first-of-type) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  .pricing-buttons button:last-of-type {
    border-bottom-right-radius: 0.5em;
    border-top-right-radius: 0.5em;
  }

  .pricing-buttons button:last-of-type span {
    border-right: 0;
  }

  .pricing-buttons button:not(:last-of-type) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  .pricing-buttons button {
    border: none;
    margin: 0;
    box-shadow: none;
    width: 50%;
  }

  .pricing-buttons button span {
    border-right: 0.1px solid rgba(176, 176, 176, 1);
  }

  .pricing-item {
    display: flex;
    opacity: 0.7;
    transition: .2s;
  }

  .pricing-item:hover{
    opacity: 1;
    margin-top: -15px;

  }
}

.discount-warning {
  color: $warning-color;
}

// Transition Page
.page-enter,
.page-appear {
  opacity: 0;
}

.page-enter-active {
  opacity: 1;
  transition: opacity 300ms linear;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  transition: opacity 300ms linear;
}

.w-min-300px {
  min-width: 300px;
}

.locked-container {
  background-image: url("~@assets/img/bg-blocked.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0 0 8px 8px white inset;
}

.locked-inner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0 0 10px 10px white;
  background: #ffffffb5;
  padding: 3em;
  cursor: pointer;
}

// Payment items

.payment-option {
  &__container {
    font-weight: 500;
    width: 100%;
    border-radius: 5px;
    height: 80px;
    transition: all 0.3s ease-in-out;
    border: 1px solid #EAECF0;
    box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover,
    &.active {
      cursor: pointer;
      border: 1px solid $theme-color-1;
      transition: all 0.3s ease-in-out;
    }

    &.active {
      background: #f4ebff;
      border: 1px solid $theme-color-1;
    }
  }

  &__container,
  &__type,
  &__number,
  &__name,
  &__default {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__type {
    width: 18%;
  }

  &__number,
  &__name {
    width: 34%;
  }

  &__default {
    width: 14%;
  }

  &__image {
    max-height: 4rem;
    max-width: 100%;
  }
}

@media only screen and (max-width: 768px) {

  .payment-option {
    &__container {
      flex-direction: column;
      height: 8em;
    }

    &__number,
    &__name {
      padding-bottom: 1em;
      width: 100%;
    }

    &__image {
      max-height: 3rem;
    }
  }
}

.no-pills .nav-pills {
  display: none;
}

.flip-card {
  overflow: hidden;
}

.flip-card-inner {
  transition: all 0.6s linear;
  transform-style: preserve-3d;
}

.flip-card.add-card .flip-card-inner {
  transform: rotateY(180deg);

  .flip-card-back {
    opacity: 1;
    height: auto;
  }

  .flip-card-front {
    opacity: 0;
    height: 0px;
  }
}

.flip-card-front,
.flip-card-back {
  backface-visibility: hidden;
  transition: opacity 0.3s linear;
}

.flip-card-back {
  opacity: 0;
  height: 0px;
  transform: rotateY(180deg);
}

/* 41.Form Wizard */
.wizard-basic-step {
  min-height: 85px;
}

.wizard-buttons {
  display: flex;

  .disabled {
    opacity: 0.5;
  }
}

.wizard {
  margin-top: -2rem;
}

.wizard ul.nav {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding-left: initial;
  padding: initial;
  background: initial;
  position: relative;
  border: initial;
  margin-bottom: 1.5rem;

  &:before {
    content: " ";
    position: absolute;
    bottom: 1px;
    width: 100%;
    height: 1px;
    z-index: 0;
    background: $separator-color-light;
  }
}

.wizard-default ul.nav {
  li {
    position: relative;
    padding: 0.5rem 1rem;
    text-align: center;

    a,
    a:focus {
      color: $separator-color;

      span,
      small {
        color: $separator-color;
        text-align: center;
      }
    }

    a:active,
    a:hover {
      color: $theme-color-1;

      span,
      small {
        color: $theme-color-1;
      }
    }

    span {
      display: block;
      font-weight: 700;
      color: $separator-color;
    }

    &.step-doing {
      a {
        color: $theme-color-1;

        span,
        small {
          color: $theme-color-1;
        }
      }
    }

    a:before {
      content: " ";
      position: absolute;
      margin-top: 10px;
      display: block;
      border-radius: 50%;
      color: initial;
      background: $separator-color-light;
      border: none;
      width: 18px;
      height: 18px;
      text-decoration: none;
      z-index: 1;
      left: 50%;
      transform: translateX(-50%);
      bottom: -6px;
    }

    &.step-doing a:after,
    &.step-done a:after {
      content: " ";
      position: absolute;
      margin-top: 10px;
      display: block;
      border-radius: 50%;
      color: initial;
      background: $theme-color-1;
      border: none;
      width: 10px;
      height: 10px;
      text-decoration: none;
      z-index: 2;
      left: 50%;
      transform: translateX(-50%);
      bottom: -2px;
    }

    &.step-done a:after {
      background: initial;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='"+ encodecolor($theme-color-1) + "' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
    }
  }

  &.disabled {

    a,
    a:active,
    a:hover {
      cursor: default;
      color: $separator-color;

      span,
      small {
        color: $separator-color;
      }
    }

    .step-doing {
      a {
        color: $theme-color-1 !important;

        span,
        small {
          color: $theme-color-1 !important;
        }
      }
    }
  }
}

@include respond-below(sm) {
  .wizard-default ul.nav li {

    small,
    span {
      display: none;
    }
  }
}

.stepper-step {
  text-align: center;
}

.justify-content-right {
  justify-content: right !important;
}

//invoice
.btn-right {
  float: right;
}

.stepper-icon {
  font-size: 35px;
  color: #909090;
}

.stepper-text {
  color: #909090;
}

.divider.active-step {
  background-color: #0f3d64 !important;
}

.stepper-icon.active-step {
  color: #0f3d64 !important;
}

.stepper-text.active-step {
  color: #0f3d64 !important;
}

.divider {
  flex-grow: 1;
  height: 2px;
  background-color: #9f9f9f;
  width: 143px;
}

.mytextdiv {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* Simple setup for this demo */

.mdl-card {
  width: 100%;
  min-height: 0;
  margin: 10px auto;
}

.mdl-card__supporting-text {
  width: 100%;
  padding: 0;
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step {
  width: 25%;
  /* 100 / no_of_steps */
}

/* Begin actual mdl-stepper css styles */

.mdl-stepper-horizontal-alternative {
  display: table;
  width: 100%;
  margin: 0 auto;
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step {
  display: table-cell;
  position: relative;
  padding: 24px;
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step:active {
  border-radius: 15% / 75%;
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step:first-child:active {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step:last-child:active {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step:hover .mdl-stepper-circle {
  background-color: #757575;
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step:first-child .mdl-stepper-bar-left,
.mdl-stepper-horizontal-alternative .mdl-stepper-step:last-child .mdl-stepper-bar-right {
  display: none;
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step .mdl-stepper-circle {
  width: 24px;
  height: 24px;
  margin: 0 auto;
  background-color: #9e9e9e;
  border-radius: 50%;
  text-align: center;
  line-height: 2em;
  font-size: 12px;
  color: white;
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step.active-step .mdl-stepper-circle {
  background-color: #0f3d64;
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step.step-done .mdl-stepper-circle:before {
  content: "\2714";
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step.step-done .mdl-stepper-circle *,
.mdl-stepper-horizontal-alternative .mdl-stepper-step.editable-step .mdl-stepper-circle * {
  display: none;
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step.editable-step .mdl-stepper-circle {
  -moz-transform: scaleX(-1);
  /* Gecko */
  -o-transform: scaleX(-1);
  /* Opera */
  -webkit-transform: scaleX(-1);
  /* Webkit */
  transform: scaleX(-1);
  /* Standard */
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step.editable-step .mdl-stepper-circle:before {
  content: "\270E";
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step .mdl-stepper-title {
  margin-top: 16px;
  font-size: 14px;
  font-weight: normal;
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step .mdl-stepper-title {
  text-align: center;
  color: rgba(0, 0, 0, 0.26);
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step.active-step .mdl-stepper-title {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step.active-step.step-done .mdl-stepper-title,
.mdl-stepper-horizontal-alternative .mdl-stepper-step.active-step.editable-step .mdl-stepper-title {
  font-weight: 300;
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step .mdl-stepper-bar-left,
.mdl-stepper-horizontal-alternative .mdl-stepper-step .mdl-stepper-bar-right {
  position: absolute;
  top: 36px;
  height: 1px;
  border-top: 2px solid #bdbdbd;
}

.line-done {
  border-color: #0f3d64 !important;
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step .mdl-stepper-bar-right {
  right: 0;
  left: 50%;
  margin-left: 20px;
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step .mdl-stepper-bar-left {
  left: 0;
  right: 50%;
  margin-right: 20px;
}

.bordered-row {
  background: $background-color;
  border-radius: $border-radius-card;
}

.row-outline {
  border: 3px solid $background-color;
  border-radius: $border-radius-card;
  transition: all 0.3s ease-in-out;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
}
.order-price {
  font-size: 1.02rem;
}

.feedback-button {
  padding: 13px !important;
}

.feedback-text {
  margin: auto;
  writing-mode: vertical-lr;
  color: #3c4149;

  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.feedback-owl {
  width: 20px;
  height: 20px;
  margin: 0 auto;
  background: url($logoPathMobile) no-repeat;
  background-position: center center;
  background-size: 20px 20px;
}

//sat cataloutge
.sat-catalogue-link {
  border-bottom: 1px solid $theme-color-1 !important;
  color: $theme-color-1 !important;
}

.download-link {
  color: $theme-color-1 !important;
}

input[type=number] {
  -moz-appearance: textfield;
}



//accordion
.accordion-title {
  cursor: pointer;

}

.accordion-hover:hover {
  background-color: #ccc;
}


.accordion-item {
  background: $background-color;
  border-radius: $border-radius-card;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 16px;
  transition: 0.4s;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.cursor-default {
  cursor: default !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

// Button video modal
.button-video-modal{
  color: #f0920e;
  font-size: .87rem;
}

.button-video-modal:hover{
  color: #f0920e;
}

.inactive {
  border: 1px solid $theme-color-1;
}
.inactive span {
  color: $theme-color-1;
}
.inactive:hover {
  background: $theme-color-1;
  border-color: $theme-color-1;

}
.inactive:hover  span{
  color: white !important
}

.inputCounter-disabled {

    background: white !important;
    cursor: text !important;

}

.price-perEmployee {
  font-size: 20px;
  font-weight: bold;
}

.simple-icon-star-outline:before {
  & {
    color: $warning-color;
  }
}

.contactMethod__edit_input {
  width: 100% !important;
  height: auto !important;
  input {
    width: 100% !important;
  height: auto !important;
  }
}

.custom-switch-small .custom-switch-input:checked + .custom-switch-btn:after {
  left: -18px;
}

.pricing-footer-link {
  border: none !important;
}

.pricing-footer-link:hover {
  background-color: transparent !important;
}

.exceeded-date {
  color: red !important;
  font-weight: bold;
}

.country-list {
  z-index: 4 !important;
}

/* 02.Phases */

.workflow__card-header {
  padding: 1.75rem;
  padding-bottom: 1.75rem;
  border-bottom: 2px solid $light-btn-background;
}

.min-height-80 {
  min-height: auto;
}

@media only screen and (min-width: 1025px) {
  .min-height-80 {
    min-height: 80vh;
    height: 100%;
  }
}

.height-inherit {
  height: inherit;
}


.workflow__card-header--navItems a{
  padding: .5rem .5rem 0rem .5rem;

  &:first-child {
    padding-left: 0;
  }
}


.phase-front-enter{
  opacity: 0;
  transform: translateX(100%);
}
.phase-front-enter-active{
  opacity: 1;
  transform: translateX(0%);
}
.phase-front-exit{
  opacity: 1;
  transform: translateX(0%);
}
.phase-front-exit-active {
  opacity: 0;
  transform: translateX(-100%);
}
.phase-front-enter-active,
.phase-front-exit-active {
  transition: opacity 500ms, transform 500ms;
}

.phase-back-enter{
  opacity: 0;
  transform: translateX(-100%);
}
.phase-back-enter-active{
  opacity: 1;
  transform: translateX(0%);
}
.phase-back-exit{
  opacity: 1;
  transform: translateX(0%);
}
.phase-back-exit-active {
  opacity: 0;
  transform: translateX(100%);
}
.phase-back-enter-active,
.phase-back-exit-active {
  transition: opacity 500ms, transform 500ms;
}

/* 42. Help Center */

.close-sub-menu {
  z-index: 5;
  span {
    font-weight: normal;
    font-size: 23px;
    color: $secondary-color;
  }
  span:hover {
    font-size: 23px;
    color: $theme-color-1;
    cursor: pointer;
  }
}

.help-search {
    position: relative;
    width: 100%;
    border-radius: 20px;
    background: $background-color;

    input {
      border: initial;
      background: transparent;
      outline: initial !important;
      padding: 0.5rem 1rem;
      line-height: 2;
      font-size: 0.8rem;
      width: 93%;
      color: $primary-color;
    }

    input:focus {
      border: initial;
      background: transparent;
      outline: initial !important;
      padding: 0.5rem 1rem;
      line-height: 2;
      font-size: 0.8rem;
      width: 93%;
      color: #212121;
    }

    .search-icon {
      font-size: 17px;
      border-radius: 10px;
      color: $secondary-color;
      position: absolute;
      width: 40px;
      height: 40px;
      bottom: -8px;
      right: 3px;
      text-align: center;
      cursor: pointer;

      &:hover {
        color: $theme-color-1;
      }
    }
  }

/* 42. Tooltip  */
.text-decoration-underline{
  text-decoration: underline;
}

.tooltip {
  opacity: 0;
  // transition: transition: opacity .25s cubic-bezier(0,1,.4,1),
    // transform .25s cubic-bezier(.18,1.25,.4,1);
}

.tooltip.show {
  opacity: 1;
}


.tooltip-inner {
  padding: 20px;
  color: $primary-color;
  background-color: $foreground-color;
  border: 1px solid $separator-color;
  border-radius:  $border-radius-card;
  @include depth(1);
}

.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^="right"] .arrow::before {
  border-right-color: $separator-color;
}

.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-right-color: $foreground-color;
}

.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^="left"] .arrow::before {
  border-left-color: $separator-color;
}

.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-left-color: $foreground-color;
}

.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: $separator-color;
}

.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-bottom-color: $foreground-color;
}

.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^="top"] .arrow::before {
  border-top-color: $separator-color;
}

.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-top-color: $foreground-color;
}

.tooltip .arrow::before,
.tooltip .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::after,
.bs-tooltip-auto[x-placement^="right"] .arrow::after {
  border-width: 0.4rem 0.4rem 0.4rem 0;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  left: 0;
  border-right-color: $separator-color;
}

.bs-tooltip-right .arrow::after,
.bs-tooltip-auto[x-placement^="right"] .arrow::after {
  left: 1px;
  border-right-color: $foreground-color;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::after,
.bs-tooltip-auto[x-placement^="right"] .arrow::after {
  border-width: 0.4rem 0.4rem 0.4rem 0;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  bottom: 0;
  border-top-color: $separator-color;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::after,
.bs-tooltip-auto[x-placement^="top"] .arrow::after {
  border-width: 0.4rem 0.4rem 0;
}

.bs-tooltip-top .arrow::after,
.bs-tooltip-auto[x-placement^="top"] .arrow::after {
  bottom: 1px;
  border-top-color: $foreground-color;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::after,
.bs-tooltip-auto[x-placement^="top"] .arrow::after {
  border-width: 0.4rem 0.4rem 0;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::after,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.4rem 0.4rem 0.4rem;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  top: 0;
  border-bottom-color: $separator-color;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::after,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.4rem 0.4rem 0.4rem;
}

.bs-tooltip-bottom .arrow::after,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::after {
  top: 1px;
  border-bottom-color: $foreground-color;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::after,
.bs-tooltip-auto[x-placement^="left"] .arrow::after {
  border-width: 0.4rem 0 0.4rem 0.4rem;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  right: 0;
  border-left-color: $separator-color;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::after,
.bs-tooltip-auto[x-placement^="left"] .arrow::after {
  border-width: 0.4rem 0 0.4rem 0.4rem;
}

.bs-tooltip-left .arrow::after,
.bs-tooltip-auto[x-placement^="left"] .arrow::after {
  right: 0;
  border-left-color: $foreground-color;
}


/* 42. Onboarding Home Card */

.onboarding-button {
  cursor: pointer;
}

.onboarding-button:hover {
  opacity: 1;
}

.center-layout {
  align-items: center;
  justify-content: center;
}

.onboarding-card {
  overflow: hidden;
  border: 1px solid rgba(0,0,0,.2)
}
.onboarding-card:hover {
  overflow: hidden;
  border: 1px solid $theme-color-1
}

.onboarding-complete-step {
  transition: opacity .4s ease;
  opacity: .4;
}
.onboarding-icon {
  width: 40px;
  height: 40px;
  position: relative;
}

.onboarding-icon-house {
  stroke: #000000;
}

.onboarding-progress-bar {
  height: 5px;
  border-radius: 8px;
}

.onboarding-progress-bar-complete {
  background-color: #1bb157;
  transition: all 1s;
}

.onboarding-progress-bar-incomplete {
  background-color: #e8e8e8;
  transition: all 1s;
}

.onboarding-icon-step {
  flex: 1 1 auto;
}

.onboarding-accordion-toggle-button {
  fill: #737376;
  position: relative;
  width: 20px;
  height: 20px;
  margin: 5px;
}

.onboarding-step-wrap{
  border-radius: 8px;
}

.onboarding-step-button-wrap {
  padding: 15px;
  cursor: pointer;
}

.onboarding-step-wrap:hover{
  border: 1px solid $theme-color-1;
  position: relative;
  z-index: 0;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.10);
}

.onboarding-step-wrap-selected {
  border: 1px solid $theme-color-1;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.10);
}

.onboarding-step-circle {
  border-radius: 50%;
  height: 24px;
  width: 24px;
  border: 1px solid #222;
}

.onboarding-step-completed {
  background-color: #1bb157;
  border-color: #1bb157;
  color: #fff;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  border: 1px solid #fff;
}

.onboarding-complete {
  padding: 1px;
  position: absolute;
  top: 60%;
  left: 65%;
}

.onboarding-check {
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: bottom;
  margin-bottom: 2px;
}

.onboarding-check path {
  fill: currentColor;
}

.onboarding-video-iframe {
  height: 250px;
  width: 80%;
}

.onboarding-step-text-success {
  color: #1bb157;
}

.credential-card {
  position: relative;
  min-height: 260px;
  .credential-btn {
    position: sticky;
    top: 200%;
    left: 100%;
    height: 30px;
    width: auto;
  }
}

.invoice-concept-card-help{
  left: 79%;
  top: 5%;
}

.scrolling-wrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  .scrolling-item {
    flex: 0 0 auto;
  }
  .scrolling-item:hover {
    cursor: pointer;
  }
}

@import './views/workflows.scss';

@import './core/monthly-date-picker.scss';

.grecaptcha-badge {
  visibility: hidden;
}

/* Icons */
@import './core/icons.scss';

.bank-container {
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  height: 80px;
  width: 100%;
  transition: all 0.3s ease-in-out;
  &:hover {
    border: 1px solid $theme-color-1;
  }

  .bank-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    /* .bank-item-icon {
      height: 40px;
      width: 40px;
      margin-right: 10px;
    } */
  }
}
.icon {
  height: 16px;
  width: 16px;
  min-height: 16px;
  min-width: 16px;
  path{
    fill: #525364;
  }
  &-large {
    height: 46px;
    width: 46px;
    min-height: 16px;
    min-width: 16px;
  }
}

.main-title {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @include respond-above(sm) {
    flex-direction: row;
  }
}

// Add styles to rever bootstrap styles
.reset-styles {
  label {
    margin-bottom: unset;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
  .modal-content {
    height: 100%;
    overflow: auto;
  }
}

.visx-tooltip {
  z-index: 9999; /* or whatever height is appropriate */
}

.react-datepicker__day--disabled {
  color: #ccc;
  cursor: not-allowed;
}
