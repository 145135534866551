

.workflow-empty-container {
    height: 76vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    .workflow-empty-add-icon {
        margin-bottom: 18px;
        height: 100px;
        width: 100px;
        min-height: 100px;
        min-width: 100px;
        path{
          fill: #2ca949;
        }
    }
    .workflow-empty-locked-icon {
        @extend .workflow-empty-add-icon;
        path{
          fill: #6b6f76;
        }
    }



    .workflow-empty-request-button {
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        white-space: nowrap;
        border-radius: 8px;
        flex-shrink: 0;
        margin: 0px;
        font-weight: 500;
        line-height: normal;
        transition-property: border, background, color, box-shadow;
        transition-duration: 0.15s;
        user-select: none;
        -webkit-app-region: no-drag;
        min-width: 32px;
        height: 32px;
        padding: 0px 14px;
        border: 1px solid rgb(223, 225, 228);
        box-shadow: rgba(0, 0, 0, .07) 0px 1px 1px !important;
        background: rgb(255, 255, 255);
        color: rgb(60, 65, 73);
    }
    
    .workflow-empty-request-button:enabled:hover {
        background: rgb(244, 245, 248);
        border-color: rgb(201, 203, 205);
        color: rgb(60, 65, 73);
    }
    
    .workflow-empty-request-button:not(:disabled):not(.disabled):active, 
    .workflow-empty-request-button:not(:disabled):not(.disabled).active, 
    .show > .workflow-empty-request-button.dropdown-toggle {
        background: rgb(244, 245, 248);
        border-color: rgb(201, 203, 205);
        color: rgb(60, 65, 73);
    }
    
    .workflow-empty-request-button:hover {
        transition-duration: 0s;
    }
    
    .workflow-empty-request-button svg{
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        max-width: 22px;
        max-height: 22px;
        margin-right: 10px;
        transition-property: fill, stroke;
        transition-duration: 0.15s;
        fill: rgb(107, 111, 118);
    }
    
    .workflow-empty-request-button-text {
        font-size: 14px; 
    }
  
}

.workflow-status-card {
    border: 1px solid #e6eaf0;
    border-radius: 8px;
    background: white;
    padding: 12px;

    .workflow-status-card-header {
        margin-bottom: 10px;
        .status-card-header-title {
            margin-bottom: 0px;
            padding: 0px;
        }
        .status-card-header-subtitle {
            font-size: 12px;

        }
    }
    .workflow-status-card-body {
        .workflow-card-status {
            display: flex;
            justify-content: space-around;
            align-items: center;
            .workflow-card-status-icon {
                height: 35px;
                width: 35px;
                min-height: 35px;
                min-width: 35px;
                path{
                  fill: $theme-color-1;
                }
            }
            .card-status-description {
                display: flex;
                justify-content: center;
                width: 65%;
            }
        }
        .card-divider {
            display: flex;
            flex-direction: row;
            color: #c1c5ca;
            margin: 0px;
        }
        .card-divider:before, .card-divider:after{
            content: "";
            flex: 1 1;
            border-bottom: 1px solid #e6eaf0;
            margin: auto;
        }
        .card-divider:before {
            margin-right: 10px
        }
        .card-divider:after {
            margin-left: 10px
        }
        .workflow-card-documents  {
            display: flex;
            justify-content: space-between;
            .workflow-documents-acuses {
                .documents-acuses-download-btn {
                    border: 1px solid red;
                }
            }
        }
    }
}
.label-icon {
    width: 22px;
    height: 17px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    path {
        fill: #6b6f76;
    }
    &:hover {
        cursor: pointer;
    }
}

.workflow-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    .card-divider {
        display: flex;
        flex-direction: row;
        color: #c1c5ca;
        margin: 0px;
    }
    .card-divider:before, .card-divider:after{
        content: "";
        flex: 1 1;
        border-bottom: 1px solid #e6eaf0;
        margin: auto;
    }
    .card-divider:before {
        margin-right: 0px
    }
    .card-divider:after {
        margin-left: 0px
    }
    
    .workflow-month-status {
        height: auto;
        .month-status-container {
            display: flex;
            align-items: center;
            height: 100%;
            .status-description {
                display: flex;
                gap: 10px;
                align-items: flex-start;
                .status-description-icon {
                    height: 65px;
                    width: 65px;
                    min-height: 65px;
                    min-width: 65px;
                    path{
                      fill: #6b6f76;
                    }
                }
                .status-description-text {
                    //border: 1px solid red;
                }
            }
            .status-button {
                text-align: end;
            }
        }
    }
    
}
.divider-solid {
    border-top: 1px solid #D9D9D9;
    margin: 10px 0px 10px;
}