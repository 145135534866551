@import '@assets/css/sass/_mixins';

.main {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @include respond-above(sm) {
    flex-direction: row;
  }
}

.description {
  flex: 1;
}
