.root {
  height: 100%;
  color:  #7a7a7a;
  background-color: white!important;
  justify-content: center;
}
.main {
  width: 500px;
  flex-direction: column;
  margin-top: 50px;
  gap: 32px;
}
.invoiceContainer {
  flex-direction: column;
  gap: 20px;
  .invoiceHeader {
    .invoiceHeaderTexts {
      flex: 0 0 350px;
      flex-direction: column;
    }
    .invoiceImg {
      img {
        width: 100%;
        height: fit-content;
      }
    }
  }
  .invoiceContent {
   justify-content: space-between;
  }
}
.card{
  padding: 32px 32px 24px 32px;
  width: 100%;
  border-radius: 12px;
  box-shadow: 0 2px 5px 0 rgb(50 50 93 / 10%), 0 1px 1px 0 rgb(0 0 0 / 7%);
  border-width: 1px;
  border-color: #e1e1e1;
  border-style: solid;
  flex-direction: column;
}
.invoiceTop{
  gap: 32px;
}
.invoiceBottom{
  .invoiceNumberText {
    margin-bottom: 26px;
  }
  .invoiceBillingDate{
    margin-bottom: 8px;
  }
  .invoiceItems {
    justify-content: space-between;
    .invoiceItemText {
      flex-direction: column;
    }
  }
}
.companyContainer {
  align-items: center;
  gap: 8px;
  .logo {
    border-radius: 100%;
    width: 32px;
    height: 32px;
    text-align: center;
    background-color: white;
    background-position: center;
    background: url(https://files.stripe.com/files/MDB8YWNjdF8xMDQyWVo0Zm5HYjYwZGpZfGZfbGl2ZV9yOHBwSU90WE9hMVpKdlRhSWxxYmI5Q1Q00kw0mI9oS);
    background-origin: border-box;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    line-height: 100%;
  }
  .companyText {
    font-weight: 500;
  }
}


.footer {
  justify-content: center;
  font-size: 12px;
  line-height: 14px;
}

.divider {
  margin-top: 15px;
}

.itemDivider{
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 0;
}

.bold {
  font-weight: 500;
}
.black{
  color: #1a1a1a;
}
.itemTextGray {
  color: #7a7a7a;
}
.bigNumber {
  color: #1a1a1a;
  font-size: 36px;
  line-height: 40px;
  font-weight: 600;
}
.link {
  color: #625afa !important;
  font-weight: bold;
  cursor: pointer;
}

