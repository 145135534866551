.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}
/*
Colores para las notificaciones
*/
.notification {
  background-color: #fff;
  border-width: 1px;
  border-style: solid;
}
.notification-error {
  border-color: #dc3545;
  .title {
    color: #dc3545;
  }
}

.notification-success {
  border-color: #28a745;
  .title {
    color: #28a745;
  }
}
/*
spinner
*/

.lds-ring {
  display: flex;
  position: relative;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  margin-left: 50%;
  margin-top: 5%;
}
.lds-ring div {
  box-sizing: border-box;
  display: flex;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid $theme-color-1;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $theme-color-1 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*
scrollbar del chat
*/
.message-scrollBar {
  padding-top: 5rem;
}

/*
fade para invoices
*/
.fadeInvoice-enter {
  opacity: 0;
}

.fadeInvoice-enter.fadeInvoice-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}

.fadeInvoice-leave {
  opacity: 1;
}

.fadeInvoice-leave.fadeInvoice-leave-active {
  opacity: 0;
  transition: opacity 500ms;
}
.profile-logo-container {
  position: relative;
  overflow: hidden;
}

.profile-logo-container:before {
  content: '';
  display: block;
  padding-top: 100%;
}

.profile-logo {
  position: absolute; /* Take your picture out of the flow */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; /* Make the picture taking the size of it's parent */
  width: 100%; /* This if for the object-fit */
  height: 100%; /* This if for the object-fit */
  object-fit: cover; /* Equivalent of the background-size: cover; of a background-image */
  object-position: center;
}

/*
fiel carbox
*/
.disable-on-hover:hover {
  cursor: not-allowed;
}

/*
Payment methods
*/
.payment-card-images {
  width: 100%;
  max-width: min-content;
}

.inner-card {
  box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
}

/* react-select multiselect */
.onboarding-multi-select .rs__value-container--is-multi,
.multi-select .rs__value-container--is-multi {
  padding: 1.6px 8px !important;
}

.onboarding-multi-select .rs__multi-value__label,
.multi-select .rs__multi-value__label {
  padding: 1.5px !important;
  span {
    overflow: auto;
  }
}

.onboarding-multi-select .rs__indicators,
.multi-select .rs__indicators {
  height: 2rem;
}

.multi-select .rs__control {
  flex-wrap: wrap;
}

/* react-select single */
.single-select .rs__control {
  flex-wrap: wrap;
}

.classify-expenses-logo-container {
  display: inline-block;
  position: relative;
  line-height: 0;
  flex-shrink: 0;
  width: 28px;
  height: 28px;
  font-size: 11px;
  border-radius: 8px;
  object-fit: cover;
}

[cmdk-shortcuts] {
  display: flex;
  margin-left: auto;
  gap: 8px;

  kbd {
    font-family: var(--font-sans);
    font-size: 12px;
    min-width: 20px;
    padding: 4px;
    height: 20px;
    border-radius: 4px;
    color: var(--gray11);
    background: var(--gray4);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    &.kbd-top-width {
      min-width: 33px;
    }
  }
}
