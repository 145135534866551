.nav-tabs.separator-tabs {
  border-bottom: 1px solid $separator-color;
}

.nav-tabs .nav-link {
  border: initial;
  padding-top: 1rem;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background: $foreground-color;
}

.nav-tabs .nav-link.active::before,
.nav-tabs .nav-item.show .nav-link::before {
  content: " ";
  background: $theme-color-1;
  border-radius: 10px;
  position: absolute;
  width: 100%;
  height: 3px;
  left: 0;
  top: 0;
}

.nav-tabs.separator-tabs .nav-link.active::before,
.nav-tabs.separator-tabs .nav-item.show .nav-link::before {
  content: " ";
  background: $theme-color-1;
  border-radius: 10px;
  position: absolute;
  width: 100%;
  height: 3px;
  left: 0;
  bottom: 0;
  top: unset;
}

.nav-tabs.separator-tabs .nav-link {
  border: initial;
  background: initial;
  padding-left: 0;
  padding-top: 0.5rem;
  padding-right: 0;
  margin-right: 1.5rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: $secondary-color;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border: initial;
  position: relative;
  color: $theme-color-1;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border: initial;
  color: $theme-color-1 !important;
}

.nav-tabs.vertical-tabs {
  border-bottom: 0px;
}

.nav-tabs.vertical-tabs .nav-link {
  margin-right: 0rem;
  padding-top: 1rem;
}

.card-header-tabs {
  height: 60px;
}