.modal {
  @media (min-width: 576px) {
    max-width: none !important;
    width: calc(100vw - 80px) !important;
  }

  > * {
    height: calc(100vh - 20px);
    @media (min-width: 576px) {
      height: calc(100vh - 60px);
    }
  }

  iframe {
    height: 100%;
    border: none;
  }
}
