.breadcrumb-container {
  .breadcrumb {
    @include respond-below(sm) {
      padding: 0;
    }
  }
}

.breadcrumb {
  background-color: transparent;
  margin-bottom: 0.5rem;
}

.breadcrumb-item+.breadcrumb-item::before {
  content: "|";
}