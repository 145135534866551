@import '@assets/css/sass/_mixins';

.root {
  position: relative;
  // border: solid 1px red;
  @include respond-above(sm) {
    flex: 0 0 200px;
    padding: 0 1rem 0 1rem;
  }
}

.root-without-spacing {
  position: relative;
}

.thumbnail {
  position: relative;
  border: none;
  color: white;
  text-align: left;
  border-radius: 0.5rem;
  max-width: 100%;
  cursor: pointer;
  aspect-ratio: 16 / 9;
  background: url('~/public/headerVideoPreview.jpeg') center;
  background-size: cover;
  object-fit: fill;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px;
  @include respond-above(sm) {
    width: 150px;
  }
  > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    fill: #FFFFFF88;
    width: 38.4px;
    height: 38.4px;
  }
}

.icon {
  height: 1rem;
}

.modal {
  box-shadow: none;
}

.video {
  min-height: 70vh;
}
