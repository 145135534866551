.progress {
  background-color: $separator-color;
  height: 15px;
}

.progress-bar {
  background-color: $theme-color-1;
}

.CircularProgressbar-text {
  fill: $primary-color !important;
}

.progress-bar-circle {
  width: 54px;
  height: 54px;

  svg path:first-of-type {
    stroke: $separator-color;
  }

  svg path:last-of-type {
    stroke: $theme-color-1;
  }

  &.progress-bar-banner {
    svg path:first-of-type {
      stroke: darken($theme-color-1, 15%);
    }

    svg path:last-of-type {
      stroke: $button-text-color;
    }
  }
}

.border-top-2 {
  border-top: 2px solid;
}

.task-default {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 40vh;
}

.progress-banner__container {
  max-height: 70vh;
  height: 100%;

  div:last-of-type .progress-banner {
    height: 100%;
  }
}

.progress-banner {
  transition: 0.5s;
  border-top: 2px solid;
  height: calc(100% - 1.5rem);
  min-height: 25vh;
  background-size: 200% auto;
  cursor: pointer;

  .CircularProgressbar .CircularProgressbar-text {
    fill: $button-text-color !important;
  }

  .lead {
    font-size: 1.8rem;
    color: $theme-color-1;
    margin-bottom: 0.5rem;

    @include respond-below(lg) {
      font-size: 1.5rem;
      color: $theme-color-1;
      margin-bottom: 0.2rem;
    }
  }

  i {
    font-size: 2.7rem;
    margin-bottom: 1rem;

    @include respond-below(lg) {
      font-size: 2rem;
      margin-bottom: 0.2rem;
    }
  }

  .progress-bar-circle.progress-bar-banner {
    width: 120px;
    height: 120px;

    svg path:last-of-type {
      stroke: darken($button-text-color, 10%);
    }

    @include respond-below(lg) {
      width: 80px;
      height: 80px;
    }
  }

  .progress-bar-banner {
    .progressbar-text {
      color: $primary-color !important;
      font-size: 1.7rem;
      width: 110px;
      font-weight: 300;

      @include respond-below(lg) {
        font-size: 1.2rem;
        margin-bottom: 0.2rem;
      }
    }
  }

  &:hover {
    background-position: right top;
  }
}
