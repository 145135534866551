.modal-backdrop-blur {
  .modal-backdrop.show {
    opacity: 0;
  }
  .modal {
    backdrop-filter: blur(8px);

    .modal-content {
      box-shadow: 0 -8px 8px -4px rgba(16, 24, 40, 0.03),
        0px 20px 24px -4px rgba(16, 24, 40, 0.08);
      border-radius: 12px;
    }
  }
}
.modal-new-design {
  @media (min-width: 576px) {
    .modal-dialog {
        max-width: 540px;
    }
  }
  .modal-backdrop.show {
    opacity: 0.3;
  }
  .modal {
    backdrop-filter: blur(8px);

    .modal-content {
      box-shadow: 0 -8px 8px -4px rgba(16, 24, 40, 0.03),
      0px 20px 24px -4px rgba(16, 24, 40, 0.08);
      border-radius: 12px;
      padding: 24px;
      border-radius: 12px;
      background: white;
    }
  }

}
.modal {
  .modal-header,
  .modal-body,
  .modal-footer {
    padding: 1.75rem;
  }
  .custom-modal-body {
    padding-top: 0;
  }

  @include respond-above(md) {
    .modal-xl {
      max-width: 1000px;
    }
    .modal-xxl {
      max-width: 1300px;
    }
  }

  .modal-header {
    border-bottom: 0px solid $separator-color;
  }

  .modal-footer {
    border-top: 0px solid $separator-color;
  }

  .close {
    color: $primary-color;
    text-shadow: initial;
  }

  &.fade {
    transition: all 0.1s linear;
  }

  &.fade .modal-dialog {
    transition: all 0.1s linear;
  }
}

.modal-content {
  border: initial;
  border-radius: $border-radius-card;
  background: $foreground-color;
}

.modal-right {
  padding-right: 0 !important;

  .modal-dialog {
    margin: 0 auto;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    height: 100%;
    max-width: 380px;
  }

  .modal-content {
    min-height: 100%;
  }

  .modal-header {
    height: $navbar-height;

    @include respond-below(xl) {
      height: $navbar-height-lg;
    }

    @include respond-below(lg) {
      height: $navbar-height-md;
    }

    @include respond-below(sm) {
      height: $navbar-height-xs;
    }
  }

  .modal-footer {
    justify-content: center;
  }

  .modal.fade .modal-dialog {
    transform: translate(25%, 0) !important;
  }

  .modal.show .modal-dialog {
    transform: translate(0, 0) !important;
  }
}
