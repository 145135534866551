@import '@assets/css/sass/_mixins';

.options {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @include respond-above(sm) {
    max-width: 500px;
  }
}
