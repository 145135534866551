
button {
  color: $primary-color;
  outline: initial !important;
}

.btn-arrow {
  display: inline-block;
  text-align: center;
  border-radius: 30px !important;
  width: 42px;
  height: 42px;
  line-height: 24px;
}

.btn-arrow i {
  font-size: 15px;
  display: inline-block;
  text-align: center;
}

.btn-sm.btn-arrow {
  width: 34px;
  height: 34px;
  line-height: 17px;
}

.btn-sm.btn-arrow i {
  font-size: 13px;
  line-height: 10px;
}

.btn {
  border-radius: $border-radius-card;
  outline: initial !important;
  box-shadow: none !important;
  box-shadow: initial !important;
  font-size: 0.8rem;
  padding: 0.6rem 1.3rem 0.6rem 1.3rem;
  transition: background-color box-shadow 0.1s linear;
}

.btn-shadow {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15), 0 1px 3px 1px rgba(0, 0, 0, 0.15) !important;
  transition: background-color box-shadow 0.1s linear;

  &:hover,
  &:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15),
      0 4px 6px 2px rgba(0, 0, 0, 0.15) !important;
  }
}

.btn-empty {
  background: transparent !important;
}

.btn-edit {
  padding: 0;
}

.btn-lg,
.btn-group-lg>.btn,
.btn-group-sm>.btn {
  border-radius: 50px;
}

.btn.default {
  border-radius: $border-radius;
}

.btn-primary {
  background-color: $theme-color-1;
  border-color: $theme-color-1;
  color: $button-text-color;

  &:hover {
    color: $button-text-color;
    background-color: darken($theme-color-1, 8%);
    border-color: darken($theme-color-1, 8%);
  }
}

.check-button {
  cursor: default !important;
}

.check-button.btn-primary {
  background-color: $theme-color-1 !important;
  border-color: $theme-color-1 !important;
  opacity: 1;
}

.check-button .custom-control {
  min-height: 1.1rem;
  margin-top: -7px;
}

.dropdown-menu {
  font-size: 0.8rem;
  border-radius: $border-radius;
  background: $input-background;
  border-color: rgba($primary-color, 0.15);
  padding: .25rem 0;
  margin: .2rem 0 0;
}

.dropdown-item {
  padding: 0.75rem 1.5rem;
  color: $primary-color;
}

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: $background-color;
  color: $primary-color;
}

.dropdown-item.active,
.dropdown-item:active {
  color: $button-text-color;
  text-decoration: none;
  background-color: $theme-color-1;
}

.dropdown-divider {
  border-color: $separator-color;
  margin: .25rem 0;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
  background-color: darken($theme-color-1, 12%);
  border-color: darken($theme-color-1, 12%);
  color: $button-text-color;
}

.btn-secondary {
  background-color: $theme-color-2;
  border-color: $theme-color-2;
  color: $button-text-color;

  &:hover {
    color: $button-text-color;
    background-color: darken($theme-color-2, 8%);
    border-color: darken($theme-color-2, 8%);
  }
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle {
  background-color: darken($theme-color-2, 12%);
  border-color: darken($theme-color-2, 12%);
  color: $button-text-color;
}

.btn-primary.btn-primary-gradient {
  background: linear-gradient(to right,
      $theme-color-1,
      darken($theme-color-1, 15%));

  &:hover {
    background: linear-gradient(to right,
        $theme-color-1,
        darken($theme-color-1, 8%));
  }
}

.btn-primary-gradient:not(:disabled):not(.disabled):active,
.btn-primary-gradient:not(:disabled):not(.disabled).active,
.show>.btn-primary-gradient.dropdown-toggle {
  background: linear-gradient(to right,
      $theme-color-1,
      darken($theme-color-1, 15%));
}

.btn-secondary-gradient {
  background: linear-gradient(to right,
      $theme-color-2,
      darken($theme-color-2, 15%));

  &:hover {
    background: linear-gradient(to right,
        $theme-color-2,
        darken($theme-color-2, 8%));
  }
}

.btn-secondary-gradient:not(:disabled):not(.disabled):active,
.btn-secondary-gradient:not(:disabled):not(.disabled).active,
.show>.btn-secondary-gradient.dropdown-toggle {
  background: linear-gradient(to right,
      $theme-color-2,
      darken($theme-color-2, 15%));
}

.btn-warning {
  background-color: $warning-color;
  border-color: $warning-color;
}

.btn-success,
.btn-info,
.btn-danger,
.btn-warning {
  color: $button-text-color;

  &:hover {
    color: $button-text-color;
  }
}

.btn-outline-success,
.btn-outline-info,
.btn-outline-danger,
.btn-outline-warning {
  &:hover {
    color: $foreground-color;
  }
}

.btn-light {
  color: $dark-btn-background;
  background-color: $light-btn-background;
  border-color: $light-btn-background;

  &:hover {
    color: $dark-btn-background;
    background-color: darken($light-btn-background, 8%);
    border-color: darken($light-btn-background, 8%);
  }
}

.btn-dark {
  color: $light-btn-background;
  background-color: $dark-btn-background;
  border-color: $dark-btn-background;

  &:hover {
    color: $light-btn-background;
    background-color: darken($dark-btn-background, 5%);
    border-color: darken($dark-btn-background, 5%);
  }
}

.btn-outline-dark {
  color: $dark-btn-background;
  border-color: $dark-btn-background;

  &:hover {
    color: $foreground-color;
    background-color: $dark-btn-background;
    border-color: $dark-btn-background;
  }
}

.btn-outline-white {
  color: $button-text-color;
  border-color: $button-text-color;
  background-color: initial;

  &:hover {
    color: $theme-color-1;
    background-color: $button-text-color;
  }
}

.btn-outline-light {
  color: $light-btn-background;
  border-color: $light-btn-background;

  &:hover {
    color: $foreground-color;
    background-color: $light-btn-background;
    border-color: $light-btn-background;
  }
}

.btn-outline-primary {
  color: $theme-color-1;
  border-color: $theme-color-1;

  &:hover {
    color: $foreground-color;
    background-color: $theme-color-1;
    border-color: $theme-color-1;
  }
}

.btn-outline-theme-3 {
  background: unset;
  color: $theme-color-3;
  border-color: $theme-color-3;

  &:hover {
    background-color: $theme-color-3;
    border-color: $theme-color-3;
    color: $foreground-color;
  }
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
  background-color: $theme-color-1;
  border-color: $theme-color-1;
  color: $foreground-color;
}

.btn-outline-secondary {
  color: $theme-color-2;
  border-color: $theme-color-2;

  &:hover {
    background-color: $theme-color-2;
    border-color: $theme-color-2;
    color: $foreground-color;
  }
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show>.btn-outline-secondary.dropdown-toggle {
  background-color: $theme-color-2;
  border-color: $theme-color-2;
  color: $foreground-color;
}

.btn-header-light {
  color: $separator-color;
  border-color: transparent;
  background: transparent;

  &:hover {
    background-color: transparent;
    border-color: $separator-color;
  }
}

.btn-header-primary {
  color: $theme-color-1;
  border-color: transparent;
  background: transparent;

  &:hover {
    background-color: transparent;
    border-color: $theme-color-1;
  }
}

.btn-header-secondary {
  color: $theme-color-2;
  border-color: transparent;
  background: transparent;

  &:hover {
    background-color: transparent;
    border-color: $theme-color-2;
  }
}

.btn-header-primary-light {
  color: $theme-color-3;
  border-color: transparent;
  background: transparent;

  &:hover {
    background-color: transparent;
    border-color: $theme-color-3;
  }
}

.btn-xl,
.btn-group-xl>.btn {
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: 0.05rem;
  padding: 1rem 3.5rem 0.9rem;
}

.btn-lg,
.btn-group-lg>.btn {
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: 0.05rem;
  padding: 0.75rem 2.6rem 0.6rem 2.6rem;
}

.btn-sm,
.btn-group-sm>.btn {
  padding: 0.5rem 1rem;
  font-size: 13px;
  line-height: 1.5;
}

.btn-xs,
.btn-group-xs>.btn {
  padding: 0.25rem 0.75rem 0.25rem 0.75rem;
  font-size: 0.76rem;
  line-height: 1.3;
}

.btn-xxs,
.btn-group-xxs>.btn {
  padding: 0.3rem 0.7rem;
  font-size: 0.7rem;
  line-height: 1.3;
}


.btn-primary.disabled,
.btn-primary:disabled {
  background-color: $theme-color-1;
  border-color: $theme-color-1;
  color: $foreground-color;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: $theme-color-2;
  border-color: $theme-color-2;
  color: $foreground-color;
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn-group:not(:last-child)>.btn {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.btn-group>.btn:not(:first-child),
.btn-group>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.dropdown>.btn:not(:last-child):not(.dropdown-toggle),
.dropdown>.dropdown:not(:last-child)>.btn {
  border-right: none;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.dropdown>.btn:not(:first-child),
.dropdown>.dropdown:not(:first-child)>.btn {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.btn-link {
  color: $theme-color-1;
}

.btn-link:hover {
  color: rgba($theme-color-1, 0.8);
  text-decoration: underline;
}

.white-underline-link {
  color: $button-text-color;
  text-decoration: underline;

  &:hover,
  &:active {
    color: $button-text-color;
    text-decoration: initial;
  }
}

.btn-multiple-state {
  position: relative;
  transition: opacity 500ms;

  .spinner,
  .icon {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    opacity: 0;
    visibility: hidden;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 500ms;
    color: $button-text-color;
  }

  .icon i {
    vertical-align: text-bottom;
    font-size: 18px;
  }

  .label {
    transition: opacity 500ms;
  }

  &.show-spinner {
    .label {
      opacity: 0;
    }

    .spinner {
      opacity: 1;
      visibility: visible;
    }

    &.blue-spinner .spinner span {
      background-color: $theme-color-1;
    }
  }

  &.show-success {
    .label {
      opacity: 0;
    }

    .icon.success {
      opacity: 1;
      visibility: visible;
    }
  }

  &.show-fail {
    .label {
      opacity: 0;
    }

    .icon.fail {
      opacity: 1;
      visibility: visible;
    }
  }
}

.icon-button {
  padding: 0;
  font-size: 14px;
  cursor: pointer;
  width: 34px;
  height: 34px;

  &.large {
    width: 44px;
    height: 44px;
    font-size: 18px;
  }

  &.small-icon {
    font-size: 12px;
    line-height: 32px;
  }
}

.icon-button-primary {
  &:hover {
    color: $theme-color-1;
  }
}

.icon-button-danger {
  &:hover {
    color: $danger-color;
  }
}

.icon-button-success {
  &:hover {
    color: $success-color;
  }
}

.icon-image-button {
  cursor: pointer;
}

.top-right-button {
  width: calc(100% - 80px);

  @include respond-below(xs) {
    padding-left: 0;
    padding-right: 0;
  }
}

.top-right-button-single {
  width: unset;

  @include respond-below(md) {
    width: calc(100%);
  }
}

.clickable-text {
  text-decoration: underline;
  cursor: pointer;
}

.clickable-text:hover {
  color: $theme-color-1;
}

.clickable-text-color {
  
  cursor: pointer;
  color: $theme-color-1;
}

.clickable-text-color:hover {
  text-decoration: underline;
}

.btttn {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    white-space: nowrap;
    border-radius: 8px;
    flex-shrink: 0;
    margin: 0px;
    font-weight: 500;
    line-height: normal;
    transition-property: border, background, color, box-shadow;
    transition-duration: 0.15s;
    user-select: none;
    -webkit-app-region: no-drag;
    min-width: 32px;
    height: 32px;
    padding: 0px 10px;
    border: 1px solid rgb(223, 225, 228);
    box-shadow: rgba(0, 0, 0, .07) 0px 1px 1px;
    background: rgb(255, 255, 255);
    color: rgb(60, 65, 73);
}
.btttn:hover {
  background: rgb(244, 245, 248);
  border-color: rgb(201, 203, 205);
  color: rgb(60, 65, 73);
}

.btttn:not(:disabled):not(.disabled):active {
  background: rgb(244, 245, 248);
  border-color: rgb(201, 203, 205);
  color: rgb(60, 65, 73);
}

.btttn-sm {
  height: 28px;
  font-size: 12px;
  min-width: 28px;
}

.text-button {
  box-shadow: none;
  border: 0px;
}

.topnav-chat-btn{

  .button-icon {
    height: 16px;
    width: 16px;
    min-height: 16px;
    min-width: 16px;
    path{
      fill: rgb(107, 111, 118);
    }
  }
}

.new-btn {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  white-space: nowrap;
  border-radius: 8px;
  flex-shrink: 0;
  margin: 0px;
  font-weight: 500;
  line-height: normal;
  transition-property: border, background, color, box-shadow;
  transition-duration: 0.15s;
  user-select: none;
  -webkit-app-region: no-drag;
  min-width: 32px;
  height: 32px;
  padding: 0px 14px;
  border: 1px solid rgb(223, 225, 228);
  box-shadow: rgba(0, 0, 0, .07) 0px 1px 1px !important;
  background: rgb(255, 255, 255);
  color: rgb(60, 65, 73);

  background-color: $theme-color-1;
  border-color: $theme-color-1;
  color: #fff;

  svg{
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    max-width: 22px;
    max-height: 22px;
    margin-right: 10px;
    transition-property: fill, stroke;
    transition-duration: 0.15s;
    fill: rgb(37, 93, 190);
  }

  .button-icon {
    height: 16px;
    width: 16px;
    min-height: 16px;
    min-width: 16px;
    path{
      fill: #fff
    }
  }
  
  &.btn-outline-primary{
    border: 1px solid rgb(223, 225, 228);
    box-shadow: rgba(0, 0, 0, .07) 0px 1px 1px !important;
    background: rgb(255, 255, 255);
    color: rgb(60, 65, 73);

    svg{
      fill: rgb(107, 111, 118);
    }

    .button-icon{
      path{
        fill: #6b6f76;
      }
    }
  }

}

.new-btn:enabled:hover {
  background-color: $theme-color-1;
  border-color: $theme-color-1;
  color: #fff;
  .button-icon path{
    fill: #fff
  }
}

.new-btn:not(:disabled):not(.disabled):active, 
.new-btn:not(:disabled):not(.disabled).active, 
.show > .new-btn.dropdown-toggle {
  background-color: $theme-color-1;
  border-color: $theme-color-1;
  color: #fff;
  .button-icon path{
    fill: #fff
  }
}

.btn-outline-primary.new-btn:enabled:hover {
  background: rgb(244, 245, 248);
  border-color: rgb(201, 203, 205);
  color: rgb(60, 65, 73);
  .button-icon path{
    fill: rgb(60, 65, 73);
  }
}

.btn-outline-primary.new-btn:not(:disabled):not(.disabled):active, 
.btn-outline-primary.new-btn:not(:disabled):not(.disabled).active, 
.show > .new-btn.dropdown-toggle {
  background: rgb(244, 245, 248);
  border-color: rgb(201, 203, 205);
  color: rgb(60, 65, 73);
  .button-icon path{
    fill: rgb(60, 65, 73);
  }
}

.new-btn-text {
  font-size: 14px; 
}

.new-btn svg{
  margin-right: 5px;
}

.new-btn{
  .new-btn-divider {
    box-shadow: inset 1px 0 $separator-color;
  }

  .new-btn-divider-number{
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    color: #1e2eb8;
  }

  
}

.new-btn-danger {
  @extend .new-btn;
  background-color: #dc3545;
  border-color: #dc3545;
  color: #fff;
  border: 1px solid #a62532;
}

.new-btn-danger:enabled:hover {
  background-color: #bb2d3b;
  border-color: #b02a37;
  color: #fff;
  .button-icon path{
    fill: #fff
  }
}

.new-btn-danger:not(:disabled):not(.disabled):active, 
.new-btn-danger:not(:disabled):not(.disabled).active, 
.show > .new-btn.dropdown-toggle {
  background-color: #bb2d3b;
  border-color: #b02a37;
  color: #fff;
  .button-icon path{
    fill: #fff
  }
}
.new-btn-primary {
  @extend .new-btn;
  background-color: $theme-color-1;
  border-color: $theme-color-1;
  color: #fff;
  border: 1px solid #0f3d64;
}

.new-btn-primary:enabled:hover {
  background-color: #0f3d64;
  border-color: $theme-color-1;
  color: #fff;
  .button-icon path{
    fill: #fff
  }
}

.new-btn-primary:not(:disabled):not(.disabled):active, 
.new-btn-primary:not(:disabled):not(.disabled).active, 
.show > .new-btn.dropdown-toggle {
  background-color: #0f3d64;
  border-color: $theme-color-1;
  color: #fff;
  .button-icon path{
    fill: #fff
  }
}

.button-transparent-container{
  display: flex;
  flex: initial;
  flex-direction: row;
}


.button-transparent{
  
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  white-space: nowrap;
  border-radius: 4px;
  flex-shrink: 0;
  margin: 0px;
  font-weight: 500;
  line-height: normal;
  transition-property: border, background-color, color, box-shadow;
  transition-duration: .2s;
  user-select: none;
  min-width: 28px;
  height: 28px;
  font-size: 0.75rem;
  border: 1px solid rgb(110, 121, 214);
  background-color: rgb(110, 121, 214);
  color: rgb(255, 255, 255);

  background: transparent;
  border: none;
  transition-property: background;
  transition-duration: .2s;
  padding: 0px 2px;
  box-shadow: none;
}

.button-transparent::after {
  display: none;
}

.button-transparent > :not(:first-child) {
  margin-left: 10px;
}

.button-transparent:not(:disabled):not(.disabled):active,
.show > .dropdown-toggle.button-transparent,
.button-transparent:hover {
  background: rgb(240, 243, 249);
  color: transparent;
}

.button-transparent-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  color: #6b6f76;
  path{
    fill: #6b6f76;
  }
}

.show.button-transparent-dropdown-menu {
  flex-shrink: 0;
  display: flex;
  flex: initial;
  flex-direction: column;
  -webkit-box-align: stretch;
  align-items: stretch;
}
