.datepicker-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: white;
  height: auto;
  padding: 8px $main-margin-right 8px $main-margin-left;
  border-bottom: 1px solid #e6eaf0;

  @include respond-below(lg) {
    flex-direction: column;
  }

  .datepicker-buttons {
    display: flex;
    align-items: center;
    .datepicker-year-button {
      display: flex;
      flex: initial;
      flex-direction: row;
      -webkit-box-align: center;
      align-items: center;
      padding: 8px;
      border-radius: 6px 8px;
      border: 0px solid transparent;
      background: transparent;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #3c4149;
      max-width: 100%;
      span {
        display: flex;
        align-items: center;
      }
    }
    .datepicker-year-title {
      font-size: 13px;
    }
    .datepicker-year-button > :not(:first-child) {
      margin-left: 10px;
    }

    .datepicker-year-button:hover {
      background: rgb(240, 243, 249);
    }

    .datepicker-year-select {
      display: inline-flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      white-space: nowrap;
      border-radius: 8px;
      flex-shrink: 0;
      margin: 0px;
      font-weight: 500;
      line-height: normal;
      transition-property: border, background, color, box-shadow;
      transition-duration: 0.15s;
      user-select: none;
      -webkit-app-region: no-drag;
      min-width: 32px;
      height: 32px;
      padding: 0px 14px;
      border: 1px solid rgb(223, 225, 228);
      box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px !important;
      background: rgb(255, 255, 255);
      color: rgb(60, 65, 73);
      height: 34px;
      margin: 0px;
      padding: 0px;
      border-radius: 8px;
      margin-right: 1px;
      padding: 0px 10px;
      min-width: 28px;
      font-weight: 500;
      line-height: 28px;
      font-size: 12px;
      -webkit-box-flex: 1;
      flex-grow: 0;
      -webkit-box-pack: start;
      justify-content: flex-start;
    }

    .datepicker-year-select:enabled:hover {
      background: rgb(244, 245, 248);
      border-color: rgb(201, 203, 205);
      color: rgb(60, 65, 73);
    }

    .datepicker-year-select:not(:disabled):not(.disabled):active,
    .datepicker-year-select:not(:disabled):not(.disabled).active,
    .show > .datepicker-year-select.dropdown-toggle {
      background: rgb(244, 245, 248);
      border-color: rgb(201, 203, 205);
      color: rgb(60, 65, 73);
    }

    .datepicker-year-select:hover {
      transition-duration: 0s;
    }
  }

  .datepicker-months {
    width: 98%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
    justify-items: center;
    align-items: center;
    grid-gap: 6px;
    align-items: center;

    svg {
      margin-right: 5px;
      width: 16px;
      height: 16px;
      min-width: 16px;
    }


    .month-status-fetching_invoice path {
      fill: #6b6f76;
    }
    .month-status-classifying path {
      fill: #6b6f76;
    }
    .month-status-not_ready path {
        fill: #6b6f76;
    }
    .month-status-ready path {
      fill:#b28c00;
    }
    .month-status-in_proccess path {
      fill: #b28c00;
    }
    .month-status-verify_declaration path {
      // fill: #b00020;
      fill: #6b6f76;
    }
    .month-status-submit_declaration path {
      fill: #6b6f76;
    }
    .month-status-pending_payment path {
      fill: #b28c00;
    }
    .month-status-paid path {
      // fill: #0a7700;
      fill: #2ca949;
    }

    .month-status-done path {
      fill: #2ca949;
    }

    .datepicker-month-button {
      display: flex;
      flex: initial;
      width: 100%;
      min-width: 28px;
      justify-content: center;
      align-items: center;
      text-transform: capitalize;
    }

    .datepicker-month-button > :not(:first-child) {
      margin-left: 3px;
    }

    .datepicker-month-button:hover {
      background: #f4f5f8;
    }

    .isSelected {
      background: #f4f5f8;
    }
  }
}
