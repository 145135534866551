.modal {
  @media (min-width: 576px) {
    max-width: none !important;
    width: calc(100vw - 80px) !important;
  }

  > * {
    height: calc(100vh - 20px);
    @media (min-width: 576px) {
      height: calc(100vh - 60px);
    }
  }
}

.content {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  gap: 16px;

  @media (min-width: 768px) {
    flex-direction: row;
    height: calc(100vh - 140px);
  }
}

.pdf {
  flex: 1;
}

.card {
  @media (min-width: 768px) {
    width: 180px;
    align-self: flex-start;
  }
  .cardBody {
    justify-content: space-between;
    display: flex;
    gap: 6px;
    @media (min-width: 768px) {
      flex-direction: column;
    }
  }
}