/* 19.Datatable */
.r-table {
  border: initial;

  th.sortable:hover {
    color: $theme-color-1 !important;
  }

  thead {
    th {
      box-shadow: initial;
      border: initial;
      text-align: left;
      font-weight: 700;

      &.sorted-asc {
        box-shadow: inset 0 3px 0 0 $theme-color-1;
      }

      &.sorted-desc {
        box-shadow: inset 0 -3px 0 0 $theme-color-1;
      }
    }
  }

  tr {
    td,
    th {
      padding-top: 20px;
      padding-bottom: 10px;
      border: initial;
    }

    th {
      padding-bottom: 20px;
    }
  }

  tbody {
    tr {
      td {
        border-bottom: 1px solid $separator-color-light;
      }
    }

    tr:last-of-type {
      td {
        border-bottom: initial;
      }
    }
  }
  
  .list-item-heading {
    margin-bottom: 0;
  }
}

// DECAPRICATED BELOW 
// 
// .ReactTable {
//   border: initial;
// 
//   .scrollbar-container {
//     margin: initial;
//     padding: initial;
//   }
// 
//   .rt-thead.-filters input {
//     border-radius: $border-radius;
//     outline: initial !important;
//     box-shadow: initial !important;
//     font-size: 0.8rem;
//     padding: 0.7rem 0.75rem 0.65rem 0.75rem;
//     line-height: 1.5;
//     border: 1px solid $separator-color;
//     background: $input-background;
//     color: $primary-color;
//   }
// }

// .ReactTable .rt-th,
// .ReactTable .rt-td {
//   padding-top: 20px;
//   padding-bottom: 10px;
// }
// 
// .react-table-fixed-height {
//   height: 500px;
// }
// 
// .ReactTable .rt-thead.-header {
//   box-shadow: initial;
// }
// 
// .ReactTable .rt-thead .rt-th,
// .ReactTable .rt-thead .rt-td {
//   padding-top: 10px;
//   padding-bottom: 10px;
//   box-shadow: initial;
//   border: initial;
//   text-align: left;
//   font-weight: 700;
// }
// 
// .ReactTable .rt-tbody .rt-tr-group {
//   border-bottom: 1px solid $separator-color-light;
// }
// 
// 
// .ReactTable .rt-tbody .rt-td {
//   border-right: initial;
// }
// 
// .ReactTable .rt-thead .rt-th.-sort-desc,
// .ReactTable .rt-thead .rt-td.-sort-desc {
//   box-shadow: inset 0 -3px 0 0 $theme-color-1;
// }
// 
// .ReactTable .rt-thead .rt-th.-sort-asc,
// .ReactTable .rt-thead .rt-td.-sort-asc {
//   box-shadow: inset 0 3px 0 0 $theme-color-1;
// }

.rt-loading, 
.rt-noData {
  display: block;
  position: absolute;
  background: hsla(0,0%,100%,.8);
  transition: all .3s ease;
  pointer-events: none;
}

.rt-noData {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  background: rgba(255,255,255,0.8);
  transition: all 0.3s ease;
  z-index: 1;
  pointer-events: none;
  padding: 20px;
  color: rgba(0,0,0,0.5);
}

.rt-loading {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255,255,255,0.8);
  transition: all 0.3s ease;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}

.rt-loading > div {
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  top: 50%;
  left: 0;
  font-size: 15px;
  color: rgba(0,0,0,0.6);
  -webkit-transform: translateY(-52%);
  transform: translateY(-52%);
  transition: all 0.3s cubic-bezier(.25,.46,.45,.94);
}

.rt-loading.rt-active {
  opacity: 1;
  z-index: 2;
  pointer-events: all;
}

.rt-loading.rt-active > div {
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}

.table-divided {
  border-collapse: separate;
  border-spacing: 0 0.6rem;
  width: 100% !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  td {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $separator-color-light;
    outline: initial !important;
    background: $foreground-color;
  }

  tr:last-of-type td {
    border-bottom: initial;
  }

  h6,
  p {
    margin-bottom: initial;
  }

  tbody {
    > tr[role="row"] > td:first-child:before,
     > tr[role="row"] > th:first-child:before {
      top: unset;
      box-shadow: initial;
      background-color: $theme-color-1;
      font-size: 12px;
    }

    tr {
      @include depth(1);

      &.selected {
        @include depth(2);
      }

      &.child {
        td {
          padding: 0.75rem 2rem;

          li {
            padding: 0 !important;
          }
        }
      }
    }
  }

  td,
  th {
    padding: 1.5rem;
    border: initial;
  }

  th.empty {
    &:after,
    &:before {
      content: "";
    }
  }

  .itemCheck {
    text-align: right;
    pointer-events: none;
  }
}

.order-with-arrow {
  thead {
    th {
      padding-top: 0.6em;
      padding-bottom: 0.6em;
      border: initial;

      &.sorted-asc {
        box-shadow: initial !important;
      }

      &.sorted-desc {
        box-shadow: initial !important;
      }

      &.sortable {
        // padding-left: 25px;
      }
    }

    .sortable,
    .sorted-asc,
    .sorted-asc_disabled,
    .sorted-desc,
    .sorted-desc_disabled {
      cursor: pointer;
      position: relative;
    }

    .sortable:after,
    .sortable:before,
    .sorted-asc:after,
    .sorted-asc:before,
    .sorted-asc_disabled:after,
    .sorted-asc_disabled:before,
    .sorted-desc:after,
    .sorted-desc:before,
    .sorted-desc_disabled:after,
    .sorted-desc_disabled:before {
      position: absolute;
      top: 0.4em;
      display: block;
      opacity: 0.3;
    }

    .sorted-asc:before,
    .sorted-desc:after {
      opacity: 1;
    }

    .sortable:before,
    .sorted-asc:before,
    .sorted-asc_disabled:before,
    .sorted-desc:before,
    .sorted-desc_disabled:before {
      right: 1em;
      content: "\2191";
    }

    .sortable:after,
    .sorted-asc:after,
    .sorted-asc_disabled:after,
    .sorted-desc:after,
    .sorted-desc_disabled:after {
      right: 0.5em;
      content: "\2193";
    }
  }
}

.rounded {
  .table-divided {
    tr {
      border-radius: $border-radius-rounded;
    }

    td {
      border-radius: initial;

      &:first-child {
        border-top-left-radius: $border-radius-rounded;
        border-bottom-left-radius: $border-radius-rounded;
      }

      &:last-child {
        border-top-right-radius: $border-radius-rounded;
        border-bottom-right-radius: $border-radius-rounded;
      }
    }
  }
}

.rtl {
  .rounded {
    .table-divided {
      &table {
        td {
          border-radius: initial;

          &:first-child {
            border-top-right-radius: $border-radius-rounded;
            border-bottom-right-radius: $border-radius-rounded;
          }

          &:last-child {
            border-top-left-radius: $border-radius-rounded;
            border-bottom-left-radius: $border-radius-rounded;
          }
        }
      }
    }
  }
}

.responsive-table {
  @media only screen and (max-width: 800px) {
    /* Force table to not be like tables anymore */
    table,
    tbody,
    td,
    th,
    thead,
    tr {
      display: block;
    }
    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      border: 1px solid #ccc;
    }

    td {
      /* Behave  like a "row" */
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 50%;
      white-space: normal;
      text-align: left;
    }

    td:before {
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 6px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      text-align: left;
      font-weight: bold;
    }
    /*
        Label the data
        */
    td:before {
      content: attr(data-title);
    }
  }
}


.r-table {
  &.compact-table{
    tr {
      td,
      th {
        padding: 8px;
      }

      th {
        padding-bottom: 8px;
        border-bottom: 1px solid $separator-color-light;
      }

      &.selected {
        background-color: rgb(246, 248, 250);
      }
      
    }
  }
}