main {
  position: relative;
  overflow: auto;
  -webkit-box-align: stretch;
  place-items: stretch;
  display: flex;
  flex-shrink: initial;
  flex-basis: initial;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex-grow: 1;
}

.main-center {
  @include respond-below(sm) {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.app-container {
  background: white;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  -webkit-box-flex: 1;
  height: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: $main-margin-left;
  padding-right: $main-margin-right;
}

.main-container{
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  -webkit-box-flex: 1;
  height: 100%;
  overflow: auto;
  background: rgb(249, 250, 251);
}

.main-center-container{
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 100%;
  flex-shrink: 0;
  overflow: hidden;
}

.main-center{
  width: 530px;
  animation: 0.3s ease 0.2s 1 normal both running Rmehe;
  margin: 267.5px auto auto;
}
