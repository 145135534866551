.card {
  border: initial;
  background: $foreground-color;

  .card-header .card-icon {
    right: 5px;
    top: 5px;

    i {
      font-size: 12px;
      color: $separator-color;
    }
  }

  .card-subtitle {
    margin: 0;
    margin-bottom: 1rem;
  }

  .card-header .handle {
    cursor: default;
  }

  border-radius: $border-radius-card;
  @include depth(1);

  .card-body {
    padding: 1.75rem;

    @include respond-below(xs) {
      padding: 1.25rem;
    }

    &.sm {
      padding: 1.25rem 1.75rem;
    }
  }

  .card-title {
    margin-bottom: 2rem;

    @include respond-below(sm) {
      margin-bottom: 1.25rem;
    }
  }
}

.card-img {
  border-radius: calc(0.25rem - 1px);
  height: 100%;
  object-fit: cover;
  max-height: 200px;
  width: unset;
}

.card-img-fluid {
  border-radius: calc(0.25rem - 1px);
  object-fit: cover;
}

.card-img-bottom {
  width: 100%;
  border-bottom-left-radius: calc(0.15rem - 1px);
  border-bottom-right-radius: calc(0.15rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.15rem - 1px);
  border-top-right-radius: calc(0.15rem - 1px);
}

.card-img-left {
  width: 100%;
  border-top-left-radius: calc(0.15rem - 1px);
  border-bottom-left-radius: calc(0.15rem - 1px);
  height: 100%;
  object-fit: cover;
}

.card-img-right {
  width: 100%;
  border-top-right-radius: calc(0.15rem - 1px);
  border-bottom-right-radius: calc(0.15rem - 1px);
  height: 100%;
  object-fit: cover;
}

.card-img-overlay {
  background: rgba(#000000, 0.5);
  padding: 1.75rem;

  @include respond-below(xs) {
    padding: 1rem;
  }
}

.card-top-buttons {
  padding: 1.3rem;
  right: 0;
  top: 0;

  @include respond-below(xs) {
    padding: 0.35rem;
  }
}

.card-header {
  border: initial;
  background: initial;
  padding-top: 0;
}