.divider-component {
  width: 100%;
  margin: 4px 0px 4px 0px;
  border-color: #E5E7EB;

  &.vertical {
    display: inline-block;
    height: auto;
    width: 0px;
    border: none;
    border-left: solid 1px #d7d7d7;
    margin: 0px 4px 0px 4px;
  }
}
