.navbar {
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 0px;
  height: $navbar-height;
  border-bottom: 1px solid rgb(239, 241, 244);
  min-height: 67px;
}

.navbar-title {
  height: 100%;
  color: #3c4149;
  padding: 0px 0px 0px $main-margin-left;

  &.navbar-with-tabs{
    h3 {
      padding-top: 14px;
    }
  }

  h3 {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 0px;
  }
  @include respond-below(lg) {
    margin-left: 34px;
  }
}

.navbar-with-tabs {
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 1px;
}



.navbar-left {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;
  flex-shrink: 0;
  max-width: 100vw;
  padding: 8px $main-margin-right 8px 0px;

  .topnav-search {
    position: relative;
    display: flex;
    justify-content: flex-end;
    height: 34px;
    
    @include respond-below(sm) {
      display: flex;
      justify-content: flex-end;
      width: auto;
    }
  }
  
  .search {
    position: relative;
    width: 120px;
    border-radius: 8px;
    background: $background-color;
    transition: width 0.3s ease;

    input {
      border: initial;
      background: transparent;
      outline: initial !important;
      padding: 0rem 1rem;
      line-height: 2;
      font-size: 0.8rem;
      width: 93%;
      height: 100%;
      color: $primary-color;
    }
    
    input:focus {
      border: initial;
      background: transparent;
      outline: initial !important;
      padding: 0.5rem 1rem;
      line-height: 2;
      font-size: 0.8rem;
      width: 93%;
      color: #212121;
    }

    .search-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 17px;
      border-radius: 10px;
      color: $secondary-color;
      position: absolute;
      width: 40px;
      height: 100%;
      bottom: 0px;
      right: 3px;
      text-align: center;
      cursor: pointer;

      &:hover {
        color: $theme-color-1;
      }
    }
    
    @include respond-below(lg) {
      input {
        width: 85%;
      }
    }

    @include respond-below(md) {
      input {
        width: 85%;
      }
    }

    @include respond-below(sm) {
      width: 30px;
      height: 30px;
      background: initial;
      margin-left: 0.6rem;
      color: rgba($primary-color, 0.7);
      transition: none;

      input {
        display: none;
      }

      .search-icon {
        font-size: 17px;
        width: 30px;
        height: 30px;
        bottom: -3px;
        right: 0;
        color: inherit;
      }

      &.mobile-view {
        display: flex;
        width: 100%;
        position: fixed;
        z-index: 5;
        background: $foreground-color;
        top: 0;
        right: 0;
        height: 58px;

        input {
          display: flex;
          width: 100%;
          align-content: center;
          padding-left: 10px;
        }

        span {
          top: 50%;
          transform: translateY(-50%);
          right: 16px;
          top: 28px; 
        }
      }
    }

    &-expanded {
      @extend .search;
      width: 350px;
      transition: width 0.3s ease;
    }

  }
  
  .topnav-support-button {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    white-space: nowrap;
    border-radius: 8px;
    flex-shrink: 0;
    margin: 0px;
    font-weight: 500;
    line-height: normal;
    transition-property: border, background, color, box-shadow;
    transition-duration: 0.15s;
    user-select: none;
    -webkit-app-region: no-drag;
    min-width: 32px;
    height: 32px;
    padding: 0px 14px;
    border: 1px solid rgb(223, 225, 228);
    box-shadow: rgba(0, 0, 0, .07) 0px 1px 1px !important;
    background: rgb(255, 255, 255);
    color: rgb(60, 65, 73);
  }

  .topnav-support-button:enabled:hover {
    background: rgb(244, 245, 248);
    border-color: rgb(201, 203, 205);
    color: rgb(60, 65, 73);
  }

  .topnav-support-button:not(:disabled):not(.disabled):active, 
  .topnav-support-button:not(:disabled):not(.disabled).active, 
  .show > .topnav-support-button.dropdown-toggle {
      background: rgb(244, 245, 248);
      border-color: rgb(201, 203, 205);
      color: rgb(60, 65, 73);
  }

  .topnav-support-button:hover {
      transition-duration: 0s;
  }

  .topnav-support-button svg{
      display: inline-flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      max-width: 22px;
      max-height: 22px;
      margin-right: 10px;
      transition-property: fill, stroke;
      transition-duration: 0.15s;
      fill: rgb(107, 111, 118);
  }

  .topnav-support-button-text {
      font-size: 14px; 
  }
  
  .topnav-support-button svg{
    margin-right: 5px;
  }
}

.nav-tabs-topnav {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-start;
  max-width: 100%;
}

.nav-tabs-topnav .nav-link{
  padding: 7px 0px;
  margin: 0px 16px;
  font-size: 13px;
  position: relative;
}

.nav-tabs-topnav .nav-item:first-child{
  .nav-link {
    margin-left: 0px;
  }
}

.nav-tabs-topnav .nav-link.active{
  box-shadow: inset 0 -2px $theme-color-1;
  color: $theme-color-1;
}