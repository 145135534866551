.av-invalid .av-label {
  color: $primary-color;
}

.av-tooltip .invalid-feedback {
  border-radius: 15px;
  padding: 0.5rem 1rem;
  font-size: 0.76rem;
  color: $foreground-color;
  background: $error-color;
  text-align: center;
  width: unset !important;
  position: absolute;
  z-index: 1;
  margin-top: -0.2rem;
  left: 50%;
  transform: translateX(-50%);

  &:after {
    content: "";
    position: absolute;
    top: -5px;
    left: -2.5px;
    margin-left: 50%;
    width: 10px;
    height: 5px;
    border-bottom: solid 5px $error-color;
    border-left: solid 5px transparent;
    border-right: solid 5px transparent;
  }
}

.form-inline {
  .form-group {
    @include respond-below(xs) {
      width: 100%;
    }
  }
}

.form-check-label,
.custom-control-label {
  line-height: 24px;
}

.react-tagsinput {
  background-color: $input-background;
  border: 1px solid $separator-color;
  outline: initial;
  box-shadow: initial;
}

.react-tagsinput--focused {
  border: 1px solid rgba($theme-color-1, 0.6);
}

.react-tagsinput-tag {
  background: $theme-color-1;
  border-radius: 15px;
  padding: 0px 10px;
  margin-bottom: 0px;
  display: inline-block;
  font-size: 12px;
  color: $foreground-color;
  border: initial;
}

.form-control:focus {
  background: $input-background;
  color: $primary-color;
}

.bootstrap-tagsinput {
  width: 100%;
}

.bootstrap-tagsinput input {
  padding: 0;
}

.form-control:focus {
  border: 1px solid $theme-color-7;
  box-shadow: 0 0 0 0.25rem #E9D7FE!important;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.4rem + 3px);
}

.custom-control-input:disabled~.custom-control-label::before {
  background-color: rgba($primary-color, 0.25);
}

.custom-control-input:active~.custom-control-label::before {
  background-color: transparent;
}

.custom-checkbox .custom-control-label.indeterminate::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='"+ encodecolor($button-text-color) + "' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='"+ encodecolor($button-text-color) + "' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='"+ encodecolor($button-text-color) + "'/%3E%3C/svg%3E");
}

.custom-control-label,
.custom-control-input {
  outline: initial !important;
  box-shadow: initial !important;
}

.custom-control-input {
  left: 1px;
  top: 3px;
  opacity: 0;
  z-index: 1;
}

.custom-control-label::before {
  border: 1px solid $muted-color;
  background: initial;
}

.custom-checkbox:hover .custom-control-input,
.custom-radio:hover .custom-control-input{
  cursor: pointer;
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
  background-color: $theme-color-1;
}

.custom-control-input:checked~.custom-control-label::before {
  background-color: $theme-color-1;
  box-shadow: initial !important;
  border: 1px solid $theme-color-1;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before,
.custom-radio .custom-control-input:checked~.custom-control-label::before {
  background-color: $theme-color-1;
  box-shadow: initial !important;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before,
.custom-radio .custom-control-input:checked~.custom-control-label::before {
  background-color: $theme-color-1;
  box-shadow: initial !important;
}

.custom-control-label::after,
.custom-control-label::before {
  box-shadow: initial !important;
}

.custom-control-label::before {
  top: 0.25rem;
}

.custom-control-label::after {
  top: 0.25rem;
}

.btn.rotate-icon-click i {
  transition: transform 0.5s;
}

.btn.rotate i {
  transform: rotate(180deg);
}

.btn .custom-control-label::before {
  border: 1px solid $button-text-color;
}

.btn .custom-control-input:checked~.custom-control-label::before {
  border: 1px solid $button-text-color;
}

.btn-group-icon {
  line-height: 22px;
}

.valid-tooltip,
.invalid-tooltip {
  border-radius: 15px;
  padding: 0.5rem 1rem;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.76rem;
}

.valid-tooltip {
  background-color: rgba(40, 167, 69, 1);
  margin-top: -0.2rem;
}

.invalid-tooltip {
  background-color: rgba(220, 53, 69, 1);
  margin-top: -0.2rem;
}

.valid-tooltip:after {
  content: "";
  position: absolute;
  top: -5px;
  left: -2.5px;
  margin-left: 50%;
  width: 10px;
  height: 5px;
  border-bottom: solid 5px rgba(40, 167, 69, 1);
  border-left: solid 5px transparent;
  border-right: solid 5px transparent;
}

.invalid-tooltip:after {
  content: "";
  position: absolute;
  top: -5px;
  left: -2.5px;
  margin-left: 50%;
  width: 10px;
  height: 5px;
  border-bottom: solid 5px rgba(220, 53, 69, 1);
  border-left: solid 5px transparent;
  border-right: solid 5px transparent;
}

.valid-icon {
  position: absolute;
  bottom: 8px;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0;
  font-size: 0.76rem;
  line-height: 1;
  border-radius: 0.2rem;
  right: 4px;
  color: $success-color;
}

.invalid-icon {
  position: absolute;
  bottom: 2px;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0;
  font-size: 0.875rem;
  line-height: 1;
  border-radius: 0.2rem;
  right: 4px;
  color: $error-color;
}

.was-validated .form-control:invalid~.invalid-feedback,
.was-validated .form-control:invalid~.invalid-icon,
.form-control.is-invalid~.invalid-feedback,
.form-control.is-invalid~.invalid-icon,
.was-validated .custom-select:invalid~.invalid-feedback,
.was-validated .custom-select:invalid~.invalid-icon,
.custom-select.is-invalid~.invalid-feedback,
.custom-select.is-invalid~.invalid-icon {
  display: block;
}

.was-validated .form-control:valid~.valid-feedback,
.was-validated .form-control:valid~.valid-icon,
.form-control.is-valid~.valid-feedback,
.form-control.is-valid~.valid-icon,
.was-validated .custom-select:valid~.valid-feedback,
.was-validated .custom-select:valid~.valid-icon,
.custom-select.is-valid~.valid-feedback,
.custom-select.is-valid~.valid-icon {
  display: block;
}

.autosuggest {
  position: relative;
}

.select--invalid {
  .feedback {
    margin-top: 0.25rem;
    font-size: 80%;
    color: $error-color;
  }

  .react-select__control {
    border-color: $error-color !important;
  }
}

.select--valid {
  .feedback {
    display: none;
  }
}

.react-autosuggest__suggestions-container {
  border-radius: $border-radius;
  z-index: 20;
  box-shadow: initial;
  margin-top: -1px;
  background: $foreground-color;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  top: 100%;
}

.react-autosuggest__suggestions-list {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

.react-autosuggest__suggestion {
  cursor: default;
  display: block;
  font-size: inherit;
  padding: 8px 12px;
  width: 100%;
  background: $foreground-color !important;
  color: $primary-color !important;

  &:hover,
  &:active {
    background: lighten($theme-color-1, 10%) !important;
    color: $foreground-color !important;
  }
}

.react-autosuggest__input--open {
  border: 1px solid rgba($theme-color-1, 0.6) !important;
}

.react-autosuggest__suggestions-container--open {
  border: 1px solid rgba($theme-color-1, 0.6);
  border-top: initial;
  border-width: 1px !important;
}

.react-select {
  outline: initial !important;
  box-shadow: none !important;
}

@media (max-width: 600px) {
  .react-select {
    margin-bottom: 15px;
  }
}

.react-select__value-container {
  outline: initial !important;
  box-shadow: none !important;
  padding: 0.25rem 0.75rem 0.25rem 0.75rem !important;
  // height: calc(2.4rem + 3px) !important;
  //background: $foreground-color;
}

.react-select__placeholder {
  display: flex;
  align-items: center;
  font-size: 100%;
  line-height: 1;
}

.react-select .react-select__dropdown-indicator {
  color: $secondary-color;
}

.react-select .react-select__menu-list {
  padding-bottom: 0;
  padding-top: 0;
}

.react-select .react-select__single-value,
.react-select .react-select__multi-value__label {
  color: $primary-color;
}

.react-select .react-select__dropdown-indicator,
.react-select .react-select__control--is-focused .react-select__dropdown-indicator,
.react-select .react-select__clear-indicator,
.react-select .react-select__control--is-focused .react-select__clear-indicator {
  outline: initial;
  box-shadow: initial;

  &:active,
  &:focus,
  &:hover {
    color: $theme-color-1 !important;
  }
}

.react-select__control {
  border-radius: $border-radius !important;
  border: 1px solid $separator-color !important;
  //background: $input-background !important;
  outline: initial !important;
  box-shadow: none !important;
}

.react-select__indicator-separator {
  display: none;
}

.react-select__dropdown-indicator svg {
  width: 15px;
  height: 15px;
}

.react-select__option {
  background: $foreground-color !important;
  color: $primary-color !important;

  &:hover,
  &:active {
    background: $theme-color-1 !important;
    color: $button-text-color !important;
  }
}

.react-select__option--is-selected {
  background: $theme-color-1 !important;
  color: $button-text-color !important;
}

.react-select__control--is-focused {
  border-color: rgba($theme-color-1, 0.6) !important;
}

.react-select__multi-value {
  background: transparent !important;
  border: 1px solid $separator-color;
}

.react-select__multi-value__remove:hover,
.react-select__multi-value__remove:active {
  background: transparent !important;
  color: $theme-color-1 !important;
}

.react-select .react-select__menu {
  border-radius: $border-radius !important;
  z-index: 20 !important;
  box-shadow: initial !important;
  border: 1px solid rgba($theme-color-1, 0.6) !important;
  border-top: initial !important;
  margin-top: -1px !important;
  background-color: $foreground-color;
  border-width: 1px !important;
}

.react-select__single-value {
  bottom: 0;
  top: 50%;
  display: flex;
  align-items: center;
  line-height: 1;
  font-size: 100%;
}

.react-datepicker {
  background-color: $input-background;
  border: $separator-color;
}

.react-datepicker__input-container input {
  /*   background-color: $input-background;
 */
  border: $separator-color;
}

.react-datepicker__input-container input:focus {
  border-color: rgba($theme-color-1, 0.6) !important;
}

.react-datepicker-popper {
  z-index: 20;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker__input-container input:not(.new-datepicker) {
  font-size: 0.8rem;
  padding: 0.65rem 0.75rem 0.5rem 0.75rem !important;
  height: calc(2.4rem + 3px) !important;
  border: 1px solid $separator-color;
  border-radius: $border-radius;
  width: 100%;
  outline: initial;
}

.react-datepicker {
  border: 1px solid $separator-color;
  border-radius: $border-radius;
  font-family: "Nunito", sans-serif;
}

.react-datepicker__header {
  background-color: $input-background;
  border-bottom: initial;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 35px;
  height: 35px;
  line-height: 2.3rem;
  border-radius: 0 !important;
  margin: 0;
  outline: initial !important;
}

.react-datepicker__day:hover {
  background: $separator-color;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__day--keyboard-selected {
  background: $theme-color-1;
}


.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  border-bottom-color: $foreground-color;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-bottom-color: $separator-color;
}

.react-datepicker__current-month,
.react-datepicker-time__header {
  color: $primary-color;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: $primary-color;
}

.react-datepicker__input-container input:not(.new-datepicker) {
  color: $primary-color;
}


.react-datepicker__time-container {
  border-left: 1px solid $separator-color;
  width: 71px;
}

.react-datepicker-disabled input {
  background: gray !important;
}

.react-datepicker__time-container .react-datepicker__time {
  background-color: $input-background;
  color: $primary-color;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  color: $button-text-color;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-top-color: $foreground-color;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  border-top-color: $separator-color;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  background: $background-color;
}

.react-datepicker__triangle {
  left: 30px;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background: $theme-color-1;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
  background: $separator-color;
}

.react-datepicker.embedded {
  border: initial;
  width: 100%;

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 14.28%;
  }

  .react-datepicker__month-container {
    width: 100%;
  }
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 87px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  padding-left: 0;
  padding-right: 30px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background: $theme-color-1;
}

/* Dropzone */

.dropzone {
  min-height: 115px;
  border: 2px dashed $separator-color;
  background: $input-background;
  padding: 10px 10px;
  border-radius: 5px;
  color: $primary-color;
  height: auto;

  .img-thumbnail {
    height: 58px;
    width: 100%;
    object-fit: cover;
  }
}

.dropzone.dz-clickable .dz-message,
.dropzone.dz-clickable .dz-message * {
  position: relative;
  transform: translateY(-50%);
  top: 24px !important;
  margin: 0;
}

.dropzone .dz-preview.dz-image-preview,
.dropzone .dz-preview.dz-file-preview {
  width: 260px;
  height: 60px;
  min-height: unset;
  border: 1px solid $separator-color;
  border-radius: $border-radius;
  background: $foreground-color;
  color: $primary-color;

  .preview-container {
    transition: initial !important;
    animation: initial !important;
    margin-left: 0;
    margin-top: 0;
    position: relative;
    width: 100%;
    height: 100%;

    i {
      color: $theme-color-1;
      font-size: 30px;
      position: absolute;
      left: 50%;
      top: 25px;
      transform: translateX(-50%) translateY(-50%) !important;
      height: 22px;
    }
  }

  strong {
    font-weight: normal;
  }

  .remove {
    position: absolute;
    right: 6px;
    top: 6px;
    color: $theme-color-1;
    i {
      font-size: 16px;
    }
  }

  .dz-details {
    position: static;
    display: block;
    opacity: 1;
    text-align: left;
    min-width: unset;
    z-index: initial;
    color: $primary-color;
  }

  .dz-error-mark {
    color: $button-text-color !important;
    top: 15px;
    left: 25px;
    margin-left: 0;
    margin-top: 0;

    span {
      display: inline-block;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 28 28'%3E%3Cpath style='fill:"+ encodecolor($theme-color-1) + ";' d='M4.1,23.9A13.51,13.51,0,0,1,0,14,13.52,13.52,0,0,1,4.1,4.1,13.52,13.52,0,0,1,14,0a13.52,13.52,0,0,1,9.9,4.1A13.52,13.52,0,0,1,28,14a13.51,13.51,0,0,1-4.1,9.9A13.52,13.52,0,0,1,14,28,13.52,13.52,0,0,1,4.1,23.9Z'/%3E%3Cpath style='fill:"+ encodecolor($foreground-color) + ";' d='M13.13,19.35V6.17a.88.88,0,1,1,1.75,0V19.35Z'/%3E%3Crect style='fill:"+ encodecolor($foreground-color) + ";' x='13.13' y='21.07' width='1.75' height='1.64'/%3E%3C/svg%3E");
      width: 28px;
      height: 28px;
    }
  }

  .dz-success-mark {
    color: $button-text-color;
    top: 15px;
    left: 25px;
    margin-left: 0;
    margin-top: 0;

    span {
      display: inline-block;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 28 28'%3E%3Cpath style='fill:"+ encodecolor($theme-color-1) + ";' d='M4.1,23.9A13.51,13.51,0,0,1,0,14,13.52,13.52,0,0,1,4.1,4.1,13.52,13.52,0,0,1,14,0a13.52,13.52,0,0,1,9.9,4.1A13.52,13.52,0,0,1,28,14a13.51,13.51,0,0,1-4.1,9.9A13.52,13.52,0,0,1,14,28,13.52,13.52,0,0,1,4.1,23.9Z'/%3E%3Cpath style='fill:"+ encodecolor($foreground-color) + ";' d='M20.14,8.81A.77.77,0,0,1,21.2,9a.81.81,0,0,1,.25.61.83.83,0,0,1-.25.62L12.48,19l-.11.1a.82.82,0,0,1-1.23,0L6.79,14.74l-.11-.16a.49.49,0,0,1-.08-.18,1.06,1.06,0,0,1,0-.19.61.61,0,0,1,0-.19,1.16,1.16,0,0,1,0-.18,1.26,1.26,0,0,1,.08-.18,1,1,0,0,1,.11-.15.87.87,0,0,1,1.26,0l3.69,3.7L19.94,9A.72.72,0,0,1,20.14,8.81Z'/%3E%3C/svg%3E");
      width: 28px;
      height: 28px;
    }
  }

  .dz-progress {
    width: 84%;
    margin-left: 0;
    margin-top: 0;
    right: 0;
    height: 5px !important;
    left: 15px;

    .dz-upload {
      width: 100%;
      background: $theme-color-1;
    }
  }

  .dz-error-message {
    border-radius: 15px;
    background: $error-color;
    top: 60px;

    &:after {
      border-bottom: 6px solid $error-color;
    }
  }

  [data-dz-name] {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 90%;
    display: inline-block;
    overflow: hidden;
  }
}

.dropzone .dz-preview.dz-file-preview .img-thumbnail {
  display: none;
}

.dropzone .dz-error.dz-preview.dz-file-preview {
  .preview-icon {
    display: none;
  }

  .dz-error-mark,
  .dz-success-mark {
    color: $theme-color-1;
  }
}

.dropzone .dz-preview.dz-image-preview .preview-icon {
  display: none;
}

@-webkit-keyframes pulse-inner {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  10% {
    -webkit-transform: scale(0.8, 1);
    -moz-transform: scale(0.8, 1);
    -ms-transform: scale(0.8, 1);
    -o-transform: scale(0.8, 1);
    transform: scale(0.8, 1);
  }

  20% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@-moz-keyframes pulse-inner {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  10% {
    -webkit-transform: scale(0.8, 1);
    -moz-transform: scale(0.8, 1);
    -ms-transform: scale(0.8, 1);
    -o-transform: scale(0.8, 1);
    transform: scale(0.8, 1);
  }

  20% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@keyframes pulse-inner {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  10% {
    -webkit-transform: scale(0.8, 1);
    -moz-transform: scale(0.8, 1);
    -ms-transform: scale(0.8, 1);
    -o-transform: scale(0.8, 1);
    transform: scale(0.8, 1);
  }

  20% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

.dropzone .dz-preview:not(.dz-processing) .dz-progress {
  -webkit-animation: pulse-inner 3s ease infinite;
  -moz-animation: pulse-inner 3s ease infinite;
  -ms-animation: pulse-inner 3s ease infinite;
  -o-animation: pulse-inner 3s ease infinite;
  animation: pulse-inner 3s ease infinite;
}

.custom-switch {
  display: block;
  background: $separator-color;
  width: 58px;
  height: 27px;
  border: 1px solid $separator-color;

  &:after {
    width: 18px;
    height: 18px;
    top: 3px;
    margin-left: 3px;
    box-shadow: initial;
    transform: initial !important;
    animation-name: unset !important;
    background: $foreground-color;
  }

  &.rc-switch-checked:after {
    left: 30px;
  }

  &.rc-switch-checked.custom-switch-primary {
    background: $theme-color-1;
    border: 1px solid $theme-color-1;
  }

  &.rc-switch-checked.custom-switch-secondary {
    background: $theme-color-2;
    border: 1px solid $theme-color-2;
  }

  &.custom-switch-primary-inverse {
    border: 1px solid $separator-color;
  }

  &.rc-switch-checked.custom-switch-primary-inverse {
    background: $input-background;
    border: 1px solid $theme-color-1;

    &:after {
      background: $theme-color-1;
    }
  }

  &.custom-switch-secondary-inverse {
    border: 1px solid $separator-color;
  }

  &.rc-switch-checked.custom-switch-secondary-inverse {
    background: $input-background;
    border: 1px solid $theme-color-2;

    &:after {
      background: $theme-color-2;
    }
  }
}

.custom-switch .custom-switch-input+.custom-switch-btn {
  background: $separator-color;
}

.rc-switch.custom-switch.custom-switch-small {
  width: 42px !important;
  height: 23px !important;
}

.rc-switch.custom-switch.custom-switch-small:after {
  width: 14px !important;
  height: 14px !important;
  left: 1px !important;
  top: 3px !important;
}

.rc-switch-checked.custom-switch.custom-switch-small:after {
  left: 18px !important;
}

.custom-switch.custom-switch-primary .custom-switch-input:checked+.custom-switch-btn {
  background: $theme-color-1;
  border: 1px solid $theme-color-1;
}

.custom-switch.custom-switch-secondary .custom-switch-input:checked+.custom-switch-btn {
  background: $theme-color-2;
  border: 1px solid $theme-color-2;
}

.custom-switch.custom-switch-primary-inverse .custom-switch-input+.custom-switch-btn {
  border: 1px solid $separator-color;
}

.custom-switch.custom-switch-primary-inverse .custom-switch-input:checked+.custom-switch-btn {
  background: $input-background;
  border: 1px solid $theme-color-1;
}

.custom-switch.custom-switch-primary-inverse .custom-switch-input:checked+.custom-switch-btn:after {
  background: $theme-color-1;
}

.custom-switch.custom-switch-secondary-inverse .custom-switch-input+.custom-switch-btn {
  border: 1px solid $separator-color;
}

.custom-switch.custom-switch-secondary-inverse .custom-switch-input:checked+.custom-switch-btn {
  background: $input-background;
  border: 1px solid $theme-color-2;
}

.custom-switch.custom-switch-secondary-inverse .custom-switch-input:checked+.custom-switch-btn:after {
  background: $theme-color-2;
}

.custom-switch .custom-switch-input+.custom-switch-btn:after {
  background: $input-background;
}

.custom-switch .custom-switch-input+.custom-switch-btn {
  border-color: $separator-color;
}

.input-group-text {
  border-radius: $border-radius;
  background-color: lighten($input-background, 5%);
  border-color: $separator-color;
  color: $primary-color;
  font-size: 0.8rem;
  padding: 0.75rem 0.75rem 0.5rem 0.75rem;
}

.form-control {
  border-radius: $border-radius;
  outline: initial !important;
  box-shadow: initial !important;
  padding: .6rem 0.75rem 0.6rem 0.75rem;
  line-height: 1;
  border: 1px solid $separator-color;
  background: $input-background;
  color: $primary-color;
  font-size: .825rem;
}

@keyframes autofill {
  to {
    color: $primary-color;
    background: transparent;
  }
}

input:-webkit-autofill {
  animation-name: autofill;
  animation-fill-mode: both;
}

input:-webkit-autofill {
  -webkit-text-fill-color: $primary-color !important;
}

.form-control-sm,
.input-group-sm>.form-control,
.input-group-sm>.input-group-prepend>.input-group-text,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-append>.btn {
  border-radius: $border-radius;
}

.form-control-lg,
.input-group-lg>.form-control,
.input-group-lg>.input-group-prepend>.input-group-text,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-append>.btn {
  border-radius: $border-radius;
}

.custom-select {
  border-radius: $border-radius;
  padding: 0.75rem 0.75rem 0.5rem 0.75rem;
}

.input-group>.form-control:not(:first-child),
.input-group>.custom-select:not(:first-child) {
  outline: initial !important;
  box-shadow: initial !important;
}

.custom-select {
  height: calc(2.5rem + 2px);
}

.custom-select:focus {
  border-color: $theme-color-1;
}

.custom-file-input:focus~.custom-file-label {
  border-color: rgba($theme-color-1, 0.6);
}

.custom-file-label::after {
  background: $foreground-color;
  color: $primary-color;
  border-color: $separator-color;
}

.custom-file-input {
  box-shadow: initial !important;
}

.custom-file-label {
  background: $foreground-color;
  border-color: $separator-color;
}

.custom-file-label {
  box-shadow: initial !important;
  border-radius: $border-radius;
  height: calc(2.5rem + 2px);
  padding: 0.75rem 0.75rem 0.5rem 0.75rem;
}

.custom-file {
  height: calc(2.5rem + 2px);
}

.custom-file-label:focus,
.custom-file-input:focus {
  border-color: $theme-color-1;
}

.custom-file-label::after {
  height: calc(calc(2.5rem + 2px) - 1px * 2);
  padding: 0.75rem 0.75rem 0.5rem 0.75rem;
}
