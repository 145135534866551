.alert {
  border-radius: 0;
}

.alert-primary {
  color: $theme-color-1;
  background-color: rgba($theme-color-1, 0.2);
  border-color: rgba($theme-color-1, 0.1);
  svg path {
    fill: $theme-color-1;
  }
}

.alert-secondary {
  color: $theme-color-2;
  background-color: rgba($theme-color-2, 0.2);
  border-color: rgba($theme-color-2, 0.1);
  svg path {
    fill: $theme-color-2;
  }
}

.alert-success {
  color: $success-color;
  background-color: rgba($success-color, 0.2);
  border-color: rgba($success-color, 0.1);
  svg path {
    fill: $success-color;
  }
}

.alert-info {
  color: $info-color;
  background-color: rgba($info-color, 0.2);
  border-color: rgba($info-color, 0.1);
  svg path {
    fill: $info-color;
  }
}

.alert-warning {
  color: $warning-color;
  background-color: rgba($warning-color, 0.2);
  border-color: rgba($warning-color, 0.1);
  svg path {
    fill: $warning-color;
  }
}

.alert-danger {
  color: $error-color;
  background-color: rgba($error-color, 0.2);
  border-color: rgba($error-color, 0.1);
  svg path {
    fill: $error-color;
  }
}

.alert-light {
  color: $light-btn-background;
  background-color: rgba($light-btn-background, 0.2);
  border-color: rgba($light-btn-background, 0.1);
  svg path {
    fill: $light-btn-background;
  }
}

.alert-dark {
  color: $dark-btn-background;
  background-color: rgba($dark-btn-background, 0.2);
  border-color: rgba($dark-btn-background, 0.1);
  svg path {
    fill: $dark-btn-background;
  }
}

.alert-dismissible .close {
  text-shadow: initial;
}

.alert *[data-notify="title"] {
  display: block;
  font-size: 0.9rem;
}

div[data-notify="container"] {
  padding: 18px;
}

.button-icon-alert-primary {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}