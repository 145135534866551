.cropper-line {
  background-color: $theme-color-1;
}

.cropper-point {
  background-color: $theme-color-1;
}

.cropper-view-box {
  outline-color: $theme-color-1;
  outline: $theme-color-1;
}

.cropper-preview {
  text-overflow: ellipsis;
}

#cropperContainer {
  height: 300px;
  display: none;
}