.br-theme-bootstrap-stars .br-widget a.br-active:after {
  color: $theme-color-1;
}

.br-theme-bootstrap-stars .br-widget a.br-selected:after {
  color: $theme-color-1;
}

.react-rater {
  display: block !important;
  font-size: 16px;
  color: $separator-color;
}

.react-rater-star {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "simple-line-icons";
  color: $separator-color;
  margin-right: 3px;
  font-size: 28px;

  &.is-active,
  &.will-be-active {
    color: $theme-color-1;
  }

  &:after {
    content: "\e09b";
  }
}