/* 38.Tables */
.table th,
.table td {
  border-color: $separator-color-light !important;
}

.table .thead-light th {
  background-color: $separator-color-light !important;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: $separator-color-light !important;
}

.feature-row {
  margin-top: 80px;

  @include respond-below(sm) {
    margin-top: 40px;
  }
}

.selected-text-divider{
  box-shadow: inset 1px 0 $separator-color;
  height: 75%;
}

.cell-small-text{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space:nowrap;
  .list-item-heading {
    font-size: 14px !important;
  } 
}

.cell-small-text:hover {
  // overflow: hidden;
  // text-overflow: ellipsis;
  white-space:normal;
}

.table-overflow {
  overflow-x: auto;
  white-space: nowrap;
}

.table-overflow-hidden {
  overflow-x: scroll;
} 

.table-filters {
  border: 1px solid #e3e8ee;
  box-shadow: 5px 8px 10px -2px rgba(34,68,85,0.26);
  -webkit-box-shadow: 5px 8px 10px -2px rgba(34,68,85,0.26);
  -moz-box-shadow: 5px 8px 10px -2px rgba(34,68,85,0.26);
  width: 250px;
  .popover-header {
    background-color: #F5F8FA;
    color: #404452;
    font-size: 15px;
    border-bottom: none;
    padding: 8px;
  }
  .popover-body {
    border-bottom: 1px solid #F5F8FA;
    padding: 8px;
    display: flex;
    color: #404452;
  }
  .filter-select-collapse {
    padding: 0.5rem 5px;
    color: #404452;
    font-size: 12px;
    // border: 1px solid red;
    background-color: #F5F8FA;
  }
}