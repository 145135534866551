@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');

.taxes-react-grid {
  color: #667085 !important;
  height: 100%;
  overflow: auto;
}
.taxes-react-grid.fixed-header {
  max-height: calc(100vh - 120px);
}

.taxes-react-grid .disabled {
  color: #667085;
  background-color: #8181810e;
}

.taxes-react-grid .reactgrid-content .rg-pane .rg-cell {
  padding: 8px 16px;
  font-size: 12px;
  color: #667085;
}
.taxes-react-grid .reactgrid-content .rg-pane .corner-indicator:hover {
  background-color: #f9fafb;
}
.taxes-react-grid .reactgrid-content .rg-pane .with-hover:hover {
  background-color: #f9fafb;
}
.taxes-react-grid .reactgrid-content .rg-pane .corner-indicator:after {
  content: '';
  display: block;
  height: 8px;
  width: 8px;
  position: absolute;
  top: 0;
  right: -5px;
  transform: skewX(45deg);
  background: #6941c6;
}

.taxes-react-grid .padding-left-lg {
  padding-left: 24px !important;
}

.taxes-react-grid .justify-content-center {
  justify-content: center;
}

.taxes-react-grid .justify-content-end {
  justify-content: flex-end;
}

.taxes-react-grid .align-items-end {
  align-items: flex-end !important;
}

.taxes-react-grid .font-bold {
  font-weight: 600;
}

.taxes-react-grid .text-red {
  color: #cf1322;
}

.taxes-react-grid .text-blue {
  color: #2797ff;
}

.taxes-react-grid .text-green {
  color: #107c41;
}

.taxes-react-grid .light-green-bg {
  background-color: #e7f2ec;
}

.taxes-react-grid .text-md {
  font-size: 14px !important;
}

.taxes-react-grid .text-lg {
  font-size: 16px !important;
}

.taxes-react-grid .rg-celleditor {
  font-size: 12px;
  padding: 8px 16px;
  border-width: 1px;
  box-shadow: 0px 0px 8px #2797ff;
}

.taxes-react-grid .reactgrid-content .rg-pane .rg-cell-focus {
  border-color: #2797ff;
  border-width: 2px;
}

.taxes-react-grid .reactgrid-content .rg-pane .rg-cell-focus {
  border-color: #2797ff;
  border-width: 2px;
}

.taxes-react-grid .gray-border-top {
  border-top: 1px solid #98a2b3 !important;
}
