.sidebar-container {
  position: relative;
  user-select: auto;
  width: 252px;
  height: 100%;
  max-width: 330px;
  min-width: 220px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  border-right: 1px solid rgba(239, 241, 244, 1);
  background: rgba(255, 255, 255, 1);
  user-select: none !important;

  @include respond-below(lg) {
    top: 0px;
    height: unset;
    left: -252px;
    bottom: 0px;
    z-index: 5;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 0px 0px;
    position: absolute !important;
    transition: left 0.15s ease-in 0s, box-shadow 0.15s 0s;
  }

  &.sidebar-container-mobile-open {
    top: 0px;
    height: unset;
    left: 0px;
    bottom: 0px;
    z-index: 5;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 0px 16px;
    position: absolute !important;
    transition: left 0.25s ease-out 0s, box-shadow 0.15s 0s;
  }
}

.sidebar-header-container {
  padding: 14px 20px;
  flex-shrink: 0;
  display: flex;
  flex: initial;
  flex-direction: column;
  -webkit-box-align: stretch;
  align-items: stretch;

  .sidebar-header-logo {
    display: flex;
    align-items: flex-start;
    flex-direction: row;

    span {
      width: 120px;
      height: 30px;
      background: url($logoPath) no-repeat;
      background-position: left;
      background-size: 120px 30px;
    }
  }

  @include respond-below(lg) {
    margin-top: 50px;
  }
}

.sidebar-header-container > :not(:first-child) {
  margin-top: 22px;
}

.sidebar-content-container {
  display: flex;
  flex: initial;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex-grow: 1;
  overflow-y: auto;
  padding: 0px 16px;
  margin-bottom: 2px;
  gap: 4px;
}

.sidebar-footer-container {
  display: flex;
  flex: initial;
  flex-direction: column;
  padding: 0px 14px 16px;
  min-height: fit-content;
  object-fit: contain;
}

.sidebar-content-item-container {
  display: block;
  flex: initial;
  flex-direction: column;
}

.sidebar-text {
  font-style: normal;
  line-height: normal;
  text-align: left;
  color: #344054;
  font-weight: 500;
  font-size: 16px;
}

.icon-button {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  white-space: nowrap;
  border-radius: 8px;
  flex-shrink: 0;
  margin: 0px;
  font-weight: 500;
  line-height: normal;
  transition-property: border, background, color, box-shadow;
  transition-duration: 0.15s;
  user-select: none;
  -webkit-app-region: no-drag;
  min-width: 24px;
  height: 24px;
  padding: 0px 8px;
  font-size: 12px;
  border: 1px solid transparent;
  box-shadow: none;
  background: transparent;
  color: rgb(60, 65, 73);
}

.sidebar-mobile-button {
  position: fixed;
  left: env(safe-area-inset-left, 0px);
  top: 0px;
  width: 52px;
  height: 66px;
  padding: 0px 2px;
  padding-left: 8px;
}

.sidebar-backgorund {
  position: fixed;
  inset: 0px;
  background: transparent;
  z-index: 1;
}

.sidebar-nav-link-container {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  flex-grow: 1;
  height: 40px;
  border-radius: 8px;
  padding: 0px 8px;
  border-bottom: 1px solid rgb(255, 255, 255);
}

.sidebar-nav-link-container:hover {
  background-color: rgb(240, 243, 249);
}

.sidebar-nav-link-active {
  background-color: rgb(240, 243, 249);
  color: #344054;
}

.sidebar-nav-link {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.sidebar-nav-link-icon {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  path {
    stroke: #6b6f76;
  }
}

.sidebar-nav-menu-with-collapse {
  display: flex;
  align-items: stretch;
  flex-direction: column;

  .sidebar-nav-link-collapse-icon {
    height: 10px;
    width: 10px;
    margin-right: 18px;

    path {
      fill: #6b6f76;
    }
  }

  .rotate-arrow-icon {
    svg {
      transition: transform 200ms;
      transform: rotate(90deg);
    }

    &.collapsed {
      svg {
        transform: rotate(0);
      }
    }
  }

  ul {
    flex-direction: column;
    li {
      padding-left: 17px;
      border-radius: 8px;
      margin-bottom: 5px;
      a {
        font-size: 13px;
        display: block;
        padding: 8px 0;
      }

      &.active:after {
        content: ' ';
        background: $theme-color-1;
        border-radius: 10px;
        position: absolute;
        width: 4px;
        height: 4px;
        top: 50%;
        transform: translateY(-50%);
        left: -16px;
      }
    }

    li:first-child {
      margin-top: 4px;
    }
  }
}

.sidebar-nav-link-counter {
  display: block;
  -webkit-box-align: center;
  align-items: center;
  padding: 3px 6px;
  border-radius: 8px;
  height: 20px;
  background-color: rgb(240, 243, 249);
  font-size: 11px;
  font-weight: normal;
  color: rgb(30, 46, 184);
}

.sidebar-header-profile-container {
}

.sidebar-header-profile {
}
.sidebar-header-profile-dropdown {
  display: flex;
  flex: initial;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  margin-left: -6px;
  padding: 8px;
  border-radius: 8px;
  background: transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: var(--pointer);
}

.sidebar-header-profile-button {
  display: flex;
  flex: initial;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  padding: 8px;
  border-radius: 6px 8px;
  border: 0px solid transparent;
  background: transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff;
  cursor: var(--pointer);
  max-width: 100%;
}

.sidebar-header-profile-button > :not(:first-child) {
  margin-left: 10px;
}

.sidebar-header-profile-button:not(:disabled):not(.disabled):active,
.show > .dropdown-toggle.sidebar-header-profile-button,
.sidebar-header-profile-button:hover {
  background: rgb(240, 243, 249);
  color: transparent;
}

.sidebar-header-profile-image-container {
  display: inline-block;
  position: relative;
  line-height: 0;
  flex-shrink: 0;
  width: 22px;
  height: 22px;
  font-size: 11px;
  border-radius: 8px;
  object-fit: cover;
}
.new-client-form-header-profile-image-container {
  display: inline-block;
  position: relative;
  line-height: 0;
  flex-shrink: 0;
  width: 26px;
  height: 26px;
  font-size: 11px;
  border-radius: 8px;
  object-fit: cover;
}

.sidebar-header-profile-image {
  border-radius: inherit;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
}

.sidebar-header-profile-name {
  font-style: normal;
  line-height: normal;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgb(60, 65, 73);
  font-weight: 600;
  font-size: 14px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.sidebar-header-profile-name > :not(:first-child) {
  margin-left: 5px;
}

[class='sidebar-header-profile-button dropdown-toggle btn btn-secondary']::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.4em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  color: rgb(60, 65, 73);
}

.show.sidebar-header-dropdown-menu {
  flex-shrink: 0;
  display: flex;
  flex: initial;
  flex-direction: column;
  -webkit-box-align: stretch;
  align-items: stretch;
  width: 215px;
}

.sidebar-header-dropdown-item {
  flex-shrink: 0;
  display: flex;
  flex: initial;
  flex-direction: row;
  -webkit-box-align: stretch;
  align-items: center;
  height: 32px;
  color: rgb(40, 42, 48);
  white-space: nowrap;
  transition: color 0s ease 0s;
  cursor: var(--pointer);
  text-overflow: ellipsis;
  padding: 0px 14px;
}

.sidebar-header-dropdown-item:active:not(.dropdown-header) {
  background-color: #f8f8f8;
}

.sidebar-header-dropdown-item-text {
  font-style: normal;
  line-height: normal;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgb(60, 65, 73);
  font-weight: 500;
  font-size: 14px;
  overflow: hidden;
}

.sidebar-header-dropdown-item-icon {
  height: 14px;
  width: 14px;
  margin-right: 5px;

  path {
    fill: none;
    stroke: #6b6f76;
    stroke-width: 2px;
  }
}

.sidebar-header-action-container {
  -webkit-box-align: stretch;
  align-items: stretch;
  align-content: stretch;
  flex-wrap: nowrap;
  -webkit-box-flex: 1;
  flex-grow: 1;
  margin-right: -6px;
  margin-left: -6px;
}

.sidebar-header-action-button-container {
  -webkit-box-flex: 1;
  flex-grow: 1;
  align-items: flex-start;

  min-width: 0px;
  user-select: none;
  cursor: var(--pointer);

  display: flex;
  flex: initial;
  flex-direction: row;
}

.sidebar-header-action-button {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  white-space: nowrap;
  border-radius: 8px;
  flex-shrink: 0;
  margin: 0px;
  font-weight: 500;
  line-height: normal;
  transition-property: border, background, color, box-shadow;
  transition-duration: 0.15s;
  user-select: none;
  -webkit-app-region: no-drag;
  min-width: 32px;
  height: 32px;
  padding: 0px 14px;
  border: 1px solid rgb(223, 225, 228);
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px !important;
  background: rgb(255, 255, 255);
  color: rgb(60, 65, 73);

  height: 34px;
  margin: 0px;
  padding: 0px;

  border-radius: 8px;
  margin-right: 1px;
  padding: 0px 10px;
  min-width: 28px;
  font-weight: 500;
  line-height: 28px;
  font-size: 12px;
  -webkit-box-flex: 1;
  flex-grow: 1;
  -webkit-box-pack: start;
  justify-content: flex-start;
}

.sidebar-header-action-button:enabled:hover {
  background: rgb(244, 245, 248);
  border-color: rgb(201, 203, 205);
  color: rgb(60, 65, 73);
}

.sidebar-header-action-button:not(:disabled):not(.disabled):active,
.sidebar-header-action-button:not(:disabled):not(.disabled).active,
.show > .sidebar-header-action-button.dropdown-toggle {
  background: rgb(244, 245, 248);
  border-color: rgb(201, 203, 205);
  color: rgb(60, 65, 73);
}

.sidebar-header-action-button:hover {
  transition-duration: 0s;
}

.sidebar-header-action-button svg {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  max-width: 22px;
  max-height: 22px;
  margin-right: 10px;
  transition-property: fill, stroke;
  transition-duration: 0.15s;
  fill: rgb(107, 111, 118);
}

.sidebar-header-action-button-text {
  font-size: 14px;
}

.sidebar-content-separator {
  height: 8px;
  flex-shrink: 0;
}
.sidebar-content-space {
  display: flex;
  flex-shrink: initial;
  flex-basis: initial;
  flex-direction: row;
  -webkit-box-flex: 1;
  flex-grow: 1;
}

.sidebar-content-footer-container {
  padding-bottom: 16px;
}

.sidebar-content-footer-button {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 4px 4px 4px 10px;
  margin: 8px 0px 0px;
  flex-shrink: 0;
  height: 32px;

  color: rgb(40, 42, 48);
  -webkit-app-region: no-drag;
  user-select: none;
  cursor: pointer;
  transition-property: color;
  transition-duration: 0.15s;
}

.sidebar-content-footer-button:hover,
.sidebar-content-footer-button:active {
  background: rgb(244, 245, 248);
  border-radius: 8px;
}

.sidebar-content-footer-button svg {
  transition: all 0.15s ease 0s;
  width: 11px;
  height: 11px;
  margin: 1px;
}

.sidebar-content-footer-button-text {
  margin-left: 8px;
  transition-property: color, fill;
  transition-duration: 0.15s;
  font-style: normal;
  line-height: normal;
  font-weight: normal;
  text-align: left;
  color: rgb(107, 111, 118);
  font-size: 13px;
}

.sidebar-footer-card {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 10px;
  border-radius: 8px;
  margin: 0px;
  flex-shrink: 0;
  width: 100%;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(239, 241, 244);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
}

.sidebar-footer-card-text {
  font-style: normal;
  line-height: normal;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgb(60, 65, 73);
  font-weight: 600;
  font-size: 14px;
  overflow: hidden;
}

.sidebar-help-center {
  position: absolute;
  user-select: auto;
  z-index: 6;
  left: -325px;
  width: 320px;
  height: 100%;
  max-width: 330px;
  min-width: 220px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  border-right: 1px solid rgba(239, 241, 244, 1);
  background: rgba(255, 255, 255, 1);
  user-select: none !important;
  transition: left 250ms ease-out;
}
.show-help-center {
  left: 0px;
  transition: left 250ms ease-out;
}
