.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: $foreground-color !important;
  background-color: $theme-color-1 !important;
}

.nav-pills .nav-link {
  border-radius: 50px;
}

.nav-link {
  padding: 0.5rem 1rem;

  @include respond-below(sm) {
    padding: 0.5rem 0.5rem;
  }

  &.active {
    color: $theme-color-1 !important;
  }
}

.nav-pills .nav-link {
  cursor: pointer;
}

.nav-pills .active .nav-link:hover {
  color: white !important;
}

.page-item {
  padding: 10px;

  @include respond-below(sm) {
    padding: 3px;
  }

  .page-link {
    outline: initial !important;
    box-shadow: initial !important;
    line-height: 1.7;
    border-radius: 40px !important;
    min-width: 38px;
    text-align: center;
    height: 38px;
    padding: 0.55rem 0;

    @include respond-below(xs) {
      min-width: 30px;
      height: 30px;
      line-height: 0.9;
      font-size: 0.76rem;
    }

    &.next {
      background: $theme-color-1;
      color: $foreground-color;
      border: 1px solid $theme-color-1;
    }

    &.prev,
    &.prev {
      background: $theme-color-1;
      border: 1px solid $theme-color-1;
      color: $foreground-color;
    }

    &.first,
    &.last {
      background: transparent;
      color: $theme-color-1;
      border: 1px solid $theme-color-1;
      border-radius: 30px;

      &:hover {
        background: $theme-color-1;
        color: $foreground-color;
        border: 1px solid $theme-color-1;
      }
    }

    &:hover {
      background-color: transparent;
      border-color: lighten($theme-color-1, 10%);
      color: $theme-color-1;
    }
  }

  &.active .page-link {
    background: transparent;
    border: 1px solid $theme-color-1;
    color: $theme-color-1;
  }

  &.disabled .page-link {
    border-color: $separator-color;
    color: $separator-color;
    background: transparent;
  }
}

.page-link {
  background-color: transparent;
  border-color: transparent;
  color: $primary-color;
}

.btn-sm.page-link {
  padding: 0.5rem 0.5rem;
}

.pagination-lg {
  .page-item {
    padding: 15px;

    @include respond-below(sm) {
      padding: 3px;
    }

    .page-link {
      min-width: 50px;
      height: 50px;

      @include respond-below(sm) {
        min-width: 38px;
        height: 38px;
        padding: 0.55rem 0;
        line-height: 1.5;
        font-size: 0.8rem;
      }

      @include respond-below(xs) {
        min-width: 30px;
        height: 30px;
        line-height: 0.9;
        font-size: 0.76rem;
      }
    }
  }
}

.pagination-sm {
  .page-item {
    padding: 6px;

    @include respond-below(sm) {
      padding: 3px;
    }

    .page-link {
      min-width: 30px;
      height: 30px;
      line-height: 0.9;
      font-size: 0.76rem;
    }
  }
}