.group-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  min-height: 100%;
  overflow: hidden;
  -webkit-box-align: stretch;
  align-items: stretch;
  color: rgb(40, 42, 48);
  background-color: rgb(255, 255, 255);
  border-top: none;
  padding-right: env(safe-area-inset-right,0px);
  padding-left: env(safe-area-inset-left,0px);
}

